import {
    AfterViewInit,
    ApplicationRef,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { TxSession } from '../../shared/interfaces/session';
import { AuthService } from '../../shared/services/auth.service';
import { GeoDataService } from '../../shared/services/geodata.service';
import { ConnectionStateService } from '../../shared/services/connection-state.service';
import { TeamMember } from '../../shared/interfaces/teammember';
import { TeamMemberService } from '../../shared/services/team-member.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TxApiService } from '../../shared/services/txapi.service';
import {
    BehaviorSubject,
    combineLatest,
    interval,
    Observable,
    of,
    Subscription,
    switchMap
} from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import { getPermissions, getSession, getUnreadMessageCount } from '../../store/selectors/user.selectors';
import { MenuItem } from 'primeng/api';
import { PanelMenu } from 'primeng/panelmenu';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {
    distinctUntilChanged,
    filter,
    map,
    take,
    tap,
    withLatestFrom,
    shareReplay
} from 'rxjs/operators';
import {
    CALENDAR,
    IS_TEAMLEADER,
    PLANNING,
    PROJECT_OFFICE,
    STAFF_OFFICE,
    STAFF_OFFICE_ACCOUNTS,
    STAFF_OFFICE_FILE,
    STAFF_OFFICE_TEAMCONFIG,
    STAFF_OFFICE_TEAMLEITERGRUPPEN,
    STAFF_OFFICE_TIMEMODEL,
    WORKFLOW,
} from '../../store/entities/Permissions';
import { MessengerService } from '../../shared/services/messenger.service';
import { TerminalService } from '../../shared/services/terminal.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { GlobalConstants } from '../../globalConstants';
import { UserInfoService } from '../../shared/services/user-info.service';
import { ListOfUserInfo } from '../../shared/interfaces/user-info';
import { getMessageHead } from '../../store/selectors/message-center.selectors';
import { TxInfo } from '../../shared/interfaces/buchung';

declare var jQuery: any;

type CAN_OPERATOR = 'and' | '&' | 'or' | '|';

type PermissionMenuItem = MenuItem & {
    can?: string | string[];
    canOperator?: CAN_OPERATOR;
    online?: boolean;
    items?: PermissionMenuItem[];
    fullscreen?: boolean;
};

@Component({
    selector: 'tx-sidebar',
    templateUrl: './sidebar.template.html',
    styleUrls: ['./sidebar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush, // Changed to OnPush to reduce change detection cycles
})
export class TxSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
    public UnreadMessageCount$: Observable<number>;
    public UnreadPoMessageCount$: Observable<number>;
    listOfUserInfo: ListOfUserInfo;
    private tlWorkflowCountSubject = new BehaviorSubject<number>(0);
    public TlWorkflowCount$ = this.tlWorkflowCountSubject.asObservable().pipe(
        distinctUntilChanged(), // Prevent duplicate emissions
        shareReplay(1) // Share the same value across multiple subscribers
    );
    apiInfoAnfrage: Observable<TxInfo>;
    myInfo;

    private usrWorkflowCountSubject = new BehaviorSubject<number>(0);
    public UsrWorkflowCount$ = this.usrWorkflowCountSubject.asObservable().pipe(
        distinctUntilChanged(),
        shareReplay(1)
    );

    public status: string;

    // Menu items BehaviorSubject to avoid recreating the menu constantly
    private menuItemsSubject = new BehaviorSubject<PermissionMenuItem[]>([]);
    public menuItems$ = this.menuItemsSubject.asObservable();

    // Track if menu has been initialized
    private menuInitialized = false;

    // Badge elements cache
    private badgeElements: {[key: string]: HTMLElement} = {};

    constructor(
        config: AppConfigService,
        el: ElementRef,
        router: Router,
        location: Location,
        myAuthService: AuthService,
        public readonly terminalService: TerminalService,
        private readonly myTxApiservice: TxApiService,
        private readonly myGeoDataService: GeoDataService,
        private readonly myConnectionStateService: ConnectionStateService,
        private readonly route: ActivatedRoute,
        private readonly myTeamMemberService: TeamMemberService,
        private readonly myDeviceDetectionService: DeviceDetectorService,
        private readonly appRef: ApplicationRef,
        private readonly translate: TranslateService,
        private readonly store: Store<State>,
        private readonly messenger: MessengerService,
        private readonly changeRef: ChangeDetectorRef,
        private readonly breakpointObserver: BreakpointObserver,
        private readonly myUserInfoService: UserInfoService,
        private readonly zone: NgZone
    ) {
        this.$el = jQuery(el.nativeElement);
        this.config = config.getConfig();
        this.router = router;
        this.location = location;
        this.myAuthService = myAuthService;

        this.Permissions$ = this.store.select(getPermissions).pipe(
            distinctUntilChanged((prev, curr) =>
                JSON.stringify(prev) === JSON.stringify(curr)
            ),
            shareReplay(1)
        );

        this.session$ = this.store.select(getSession).pipe(
            distinctUntilChanged((prev, curr) =>
                JSON.stringify(prev) === JSON.stringify(curr)
            ),
            shareReplay(1)
        );

        this.translate.onLangChange.subscribe((translate: LangChangeEvent) => {
            // Only recreate the menu if the language changes
            this.createSideMenu();
        });

        this.isMobile = this.myDeviceDetectionService.isMobile();
        this.isTablet = this.myDeviceDetectionService.isTablet();

        this.geoDataSubscription = this.myGeoDataService.isEnabled().subscribe((state) => {
            this.geoDataEnabled = state;
        });

        this.subs.push(this.geoDataSubscription);

        this.networkIsOnlineButton$ = this.myConnectionStateService.onlineState.pipe(
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.UnreadMessageCount$ = this.store.select(getUnreadMessageCount).pipe(
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.UnreadPoMessageCount$ = this.store.select(getMessageHead).pipe(
            map((messageHead) => {
                return messageHead?.map((head) => head.unread_messages).reduce((a, b) => a + b, 0) ?? 0;
            }),
            distinctUntilChanged(),
            shareReplay(1)
        );

        this.subs.push(
            this.myUserInfoService.getUserInfo().pipe(
                withLatestFrom(this.myUserInfoService.getUserInfo())
            ).subscribe(([_, myUserInfo]) => {
                if (myUserInfo.statustext === 'OK' && Array.isArray(myUserInfo.ListOfUserInfo)) {
                    this.listOfUserInfo = myUserInfo.ListOfUserInfo[0];

                    const tlCount = this.listOfUserInfo['tl_open_workflow'];
                    const usrCount = this.listOfUserInfo['usr_wf_count'];
                    this.status = this.listOfUserInfo.status;

                    this.updateWorkflowCounts(tlCount, usrCount);
                    this.apiInfoAnfrage = this.myTxApiservice.callAPI('getSalden', {});

                    const saldenSubscription = this.apiInfoAnfrage.subscribe(
                        (myInfoData) => {
                            this.myInfo = myInfoData.ListOfSalden.filter(item => item.menuflag === 1);
                            this.changeRef.markForCheck(); // Notify Angular about the change
                        },
                        (error) => {
                            console.log('ERROR fetching salden: ' + error);
                        }
                    );

                    this.subs.push(saldenSubscription);
                }
            }),

            this.myConnectionStateService.connectionSpeed.subscribe((data) => {
                this.goodConnection = data > 500;
                this.changeRef.markForCheck(); // Notify Angular about the change
            })
        );

        this.router.events.forEach((event) => {
            if (event instanceof NavigationEnd) {
                const currentRouteSnapshotChildren = this.route.snapshot.children;

                currentRouteSnapshotChildren.forEach((child) => {
                    if (typeof child.component === 'function') {
                        if (child.routeConfig.path.includes('kalender')) {
                            if (child.paramMap.get('id') !== null) {
                                this.persoId = child.paramMap.get('id');
                                this.teamMember = this.myTeamMemberService.getTeamMember(this.persoId);
                            } else {
                                this.teamMember.name = '';
                            }
                        } else {
                            this.teamMember.name = '';
                        }
                        this.changeRef.markForCheck(); // Notify Angular about the change
                    }
                });
            }
        });
    }

    public Permissions$: Observable<string[] | null>;
    public PermissionsMenuItems$: Observable<PermissionMenuItem[]>;
    @Input() toggleSidebarListener: any = '';
    @Output() toggleSidebarEvent: EventEmitter<any> = new EventEmitter();
    $el: any;
    config: any;
    router: Router;
    location: Location;
    myAuthService: AuthService;
    session$: Observable<TxSession>;
    geoDataEnabled: any;
    geoDataSubscription: Subscription;
    private _openActions$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    openActions$ = this._openActions$.asObservable();
    wlVersion: string = GlobalConstants.appVersion;
    networkIsOnlineButton$: Observable<boolean>;
    isMobile: boolean;
    isTablet: boolean;
    goodConnection = true;
    persoId = '';
    teamMember: TeamMember = {
        name: '',
        personalnr: null,
    };
    displayAppInformation = false;

    everyXSeconds$ = interval(1000);

    subs: Subscription[] = [];

    @ViewChild('sideMenu') sideMenu: PanelMenu;

    public _translatedMenuItems: Observable<PermissionMenuItem[] | null> = of(null);

    private updateWorkflowCounts(tlCount: number, usrCount: number) {
        if (this.tlWorkflowCountSubject.getValue() !== tlCount) {
            this.tlWorkflowCountSubject.next(tlCount);
        }

        if (this.usrWorkflowCountSubject.getValue() !== usrCount) {
            this.usrWorkflowCountSubject.next(usrCount);
        }
    }

    // Let's go back to the original approach that worked before
    private initializeBadge(selector: string, countObservable: Observable<number>): Observable<number> {
        // Polling: Alle 500ms prüfen, ob das Element (menuItem) vorhanden ist
        const pollUntilBadge = interval(500).pipe(
            filter(() => !!document.querySelector(selector)),
            take(1), // sobald gefunden, fertig mit dem Polling
            tap(() => {
                const menuItem = document.querySelector(selector);
                let badge = menuItem.querySelector(`.${selector.substring(1)}-count`);
                if (!badge) {
                    badge = document.createElement('div');
                    badge.classList.add(`${selector.substring(1)}-count`, 'shadow-2');
                    menuItem.appendChild(badge);
                }
            })
        );

        return pollUntilBadge.pipe(
            switchMap(() => countObservable.pipe(
                distinctUntilChanged(),
                tap(count => {
                    const badge = document.querySelector(`${selector} .${selector.substring(1)}-count`);
                    if (badge) {
                        badge.textContent = count.toString();
                        if (count === 0) {
                            badge.classList.add('hidden');
                        } else {
                            badge.classList.remove('hidden');
                        }
                    }
                })
            ))
        );
    }

    extractTextFromHtml(html: string): string {
        // Create a temporary div to parse the HTML
        const temp = document.createElement('div');
        temp.innerHTML = html;
        // Return just the text content
        return temp.textContent || temp.innerText || '';
    }

    logout() {
        this.myAuthService.logout();
    }

    reload() {
        location.reload();
    }

    toggleSidebar(): void {
        this.toggleSidebarEvent.emit();
    }

    displayAppInfo() {
        this.displayAppInformation = true;
        this.changeRef.markForCheck(); // Notify Angular about the change
    }

    ngOnInit(): void {
        // Create side menu once during initialization
        if (!this.menuInitialized) {
            this.createSideMenu();
            this.menuInitialized = true;
        }

        // Set up badge subscriptions using the original method that was working
        this.subs.push(
            this.initializeBadge('.teamLeader-badge', this.TlWorkflowCount$).subscribe(),
            this.initializeBadge('.workflow-badge', this.UsrWorkflowCount$).subscribe(),
            this.initializeBadge('.dashboard-badge', this.UnreadMessageCount$).subscribe(),
            this.initializeBadge('.message-center-badge', this.UnreadPoMessageCount$).subscribe()
        );

        this.subs.push(
            this.everyXSeconds$.pipe(
                // Throttle the API calls to reduce frequency
                filter((_, index) => index % 5 === 0) // Only check every 5 seconds instead of every second
            ).subscribe((val) => {
                this.myTxApiservice.getCurrentQueueSize().then((data) => {
                    const cur = this._openActions$.getValue();
                    if (cur?.map((c) => c.body.hash).join() !== data?.map((d) => d.body.hash).join()) {
                        this._openActions$.next(data);
                        this.changeRef.markForCheck(); // Notify Angular about the change
                    }
                });
            })
        );
    }

    ngOnDestroy() {
        this.subs.forEach(s => s.unsubscribe());
        this.subs = [];
    }

    ngAfterViewInit(): void {
        // Delay this to ensure the menu is fully rendered
        setTimeout(() => {
            const active = this.getActiveMenuItem(this.sideMenu?.model);
            if (active?.parent) {
                this.sideMenu.collapseAll();
                this.sideMenu.handleClick(new MouseEvent('click'), active.parent);
                if (!this.sideMenu?.model[0].expanded) {
                    this.sideMenu.handleClick(new MouseEvent('click'), this.sideMenu.model[0]);
                }
                this.changeRef.markForCheck(); // Notify Angular about the change
            }
        }, 200);
    }

    mapQueueType(type: string) {
        switch (type) {
            case 'AE':
                return 'Auftrag Ende';
            case 'AB':
                return 'Auftrag Beginn';
            case 'K':
                return 'Kommen';
            case 'G':
                return 'Gehen';
            case 'PB':
                return 'Pause Beginn';
            case 'PS':
                return 'Pause Ende';
            case 'DB':
                return 'Dienstgang Beginn';
            case 'DE':
                return 'Dienstgang Ende';
        }
    }

    getActiveMenuItem(items: MenuItem[]): {
        item?: MenuItem;
        routerLink?: string[];
        active?: boolean;
        parent?: MenuItem;
    } {
        return items
            ?.map((m) => this.generateUrlMenuItemTree(m))
            .flat()
            .filter((i) => !!i)
            .find((i) => i.active);
    }

    private generateUrlMenuItemTree(item: MenuItem, parent?: MenuItem) {
        const ret = {};
        if (parent) {
            ret['parent'] = parent;
        }
        if (item.routerLink) {
            ret['item'] = item;
            ret['routerLink'] = item.routerLink;
            ret['active'] = this.router.isActive(this.router.createUrlTree(item.routerLink), false);
        }
        if (item.items?.length > 0) {
            ret['children'] = item.items.map((i) => this.generateUrlMenuItemTree(i, item));
        }
        return Object.keys(ret).length > 0
            ? [ret, !!ret['children'] ? ret['children'] : null]
                .filter((i) => !!i)
                .flat(2)
                .concat()
            : null;
    }

    private createSideMenu() {
        // Explicitly make sure we aren't triggering extra console.log calls
        // Only create the menu items once, then cache and update them only when permissions change
        this.PermissionsMenuItems$ = combineLatest([
            this.translate.get('dummy').pipe(
                map(() => {
                    return [
                        {
                            separator: true,
                            disabled: true,
                        },
                        {
                            label: this.translate.instant('sidebar.timeRegistration'),
                            icon: 'pi pi-clock',
                            routerLink: ['/buchungen'],
                            routerLinkActiveOptions: 'ui-menuitem-link-active',
                            items: null,
                        },
                        {
                            separator: true,
                            disabled: true,
                        },
                        {
                            label: this.translate.instant('sidebar.timeAccount'),
                            icon: 'pi pi-id-card',
                            routerLink: ['/zeitkonto'],
                            styleClass: 'dashboard-badge',
                            online: true,
                        },
                        {
                            label: this.translate.instant('sidebar.Workflow'),
                            icon: 'pi pi-pencil',
                            routerLinkActiveOptions: 'ui-menuitem-link-active',
                            routerLink: ['/workflow'],
                            styleClass: 'workflow-badge',
                            can: WORKFLOW,
                            online: true,
                        },
                        {
                            label: this.translate.instant('sidebar.teamLeader'),
                            icon: 'pi pi-user',
                            can: IS_TEAMLEADER,
                            styleClass: 'teamLeader-badge',
                            routerLink: ['/teamleiter'],
                            online: true,
                        },
                        {
                            label: this.translate.instant('sidebar.planing'),
                            icon: 'pi pi-calendar-plus',
                            can: PLANNING,
                            routerLink: ['/planung'],
                            online: true,
                        },
                        {
                            label: this.translate.instant('sidebar.calender'),
                            icon: 'pi pi-calendar',
                            routerLink: ['/kalender'],
                            can: CALENDAR,
                            online: true,
                        },
                        {
                            separator: true,
                            disabled: true,
                        },
                        {
                            label: this.translate.instant('sidebar.projectOffice'),
                            can: PROJECT_OFFICE,
                            online: true,
                            fullscreen: true,
                            items: [
                                {
                                    label: 'Aufträge',
                                    routerLink: ['/projectoffice/assingments'],
                                    can: PROJECT_OFFICE,
                                },
                            ],
                        },
                        {
                            label: this.translate.instant('sidebar.personalOffice'),
                            can: STAFF_OFFICE,
                            online: true,
                            styleClass: 'message-center-badge',
                            items: [
                                {
                                    label: this.translate.instant('sidebar.personalAccounts'),
                                    routerLink: ['/personaloffice/personalkonten'],
                                    can: STAFF_OFFICE_ACCOUNTS,
                                },
                                {
                                    label: this.translate.instant('sidebar.personalFile'),
                                    routerLink: ['/personaloffice/personalakten'],
                                    can: STAFF_OFFICE_FILE,
                                },
                                {
                                    label: this.translate.instant('sidebar.timeModels'),
                                    routerLink: ['/personaloffice/zeitmodelle'],
                                    can: STAFF_OFFICE_TIMEMODEL,
                                },
                                {
                                    label: this.translate.instant('sidebar.teamConfig'),
                                    routerLink: ['/personaloffice/teamkonfiguration'],
                                    can: STAFF_OFFICE_TEAMLEITERGRUPPEN,
                                },
                                {
                                    label: this.translate.instant('sidebar.messagingService'),
                                    routerLink: ['/personaloffice/messages'],
                                    can: STAFF_OFFICE,
                                },
                            ],
                        },
                    ] as PermissionMenuItem[];
                })
            ),
            this.Permissions$,
            this.networkIsOnlineButton$,
            this.session$,
        ]).pipe(
            // Only emit when there's an actual change to the resulting menu items
            distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
            map(([items, permissions, online, session]) => {
                return items
                    ?.filter((m) => {
                        if (m.can) {
                            if (typeof m.can === 'string') {
                                return permissions.includes(m.can);
                            }
                            if (m.canOperator) {
                                switch (m.canOperator) {
                                    case 'and':
                                    case '&':
                                        return m.can.map((p) => permissions.includes(p)).reduce((acc, curr) => acc && curr, true) === true;
                                    default:
                                        break;
                                }
                            }
                            return permissions.some((p) => m.can.includes(p));
                        }
                        return true;
                    })
                    .map((m) => {
                        if (m.items) {
                            m.items = m.items.filter((i: PermissionMenuItem) => {
                                if (i.can) {
                                    if (typeof i.can === 'string') {
                                        return permissions.includes(i.can);
                                    }
                                    if (i.canOperator) {
                                        switch (i.canOperator) {
                                            case '&':
                                            case 'and':
                                                return !permissions.some((p) => !i.can.includes(p));
                                            default:
                                                break;
                                        }
                                    }
                                    return permissions.some((p) => i.can.includes(p));
                                }
                                return true;
                            });
                        }
                        if (session?.reset_pw === 1) {
                            if (!this.router.url.includes('account')) {
                                this.router.navigateByUrl('/account');
                            }
                            return {
                                ...m,
                                routerLink: null,
                                items: null,
                                disabled: true,
                                command: () => {
                                    this.messenger.message('Bitte setzten Sie zuerst ein Passwort!', 'info');
                                },
                            };
                        }
                        if (m.online && !online) {
                            if (!this.router.url.includes('buchungen')) {
                                this.router.navigateByUrl('/buchungen');
                            }
                            return {
                                ...m,
                                command: () => {
                                    this.messenger.message(
                                        'Aktuell zu schlechte Netzwerkverbindung, der Bereich ist daher vorübergehend nicht verfügbar.',
                                        'info'
                                    );
                                },
                                routerLink: null,
                            };
                        }
                        return m;
                    });
            }),
            // Cache the result and share it across subscribers
            shareReplay(1)
        );

        // Subscribe once to update the menu items
        this.subs.push(
            this.PermissionsMenuItems$.subscribe(items => {
                this.menuItemsSubject.next(items);
                // Trigger change detection when menu items change
                this.changeRef.markForCheck();
            })
        );
    }
}
