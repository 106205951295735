<div class="grid flex-column-reverse sm:flex-row" [ngClass]="{
    'overflow-hidden': showMessageCenter
}">
    <div class="col-12">
    <p-accordion id="test" class="full-width" (onOpen)="onTabOpen('z5')" (onClose)="onTabClose('z5')" *ngIf="myBuchung && myBuchung.length > 0">
        <p-accordionTab [selected]="tabState?.z5 == null || tabState?.z5 == true ? true : false">
            <ng-template pTemplate="header">
                <div class="flex justify-content-between align-items-center w-100">
                    <span>{{ 'txZeitKonto.timeSumsChart' | translate }} (Beta)</span>
                </div>
            </ng-template>
            <section class="widget" style="margin-bottom: 0!important;">
                <div class="widget-body">
                    <tx-time-sums-chart></tx-time-sums-chart>
                </div>
            </section>
        </p-accordionTab>
    </p-accordion>
        </div>
    <div class="col-12 xl:col-9">
		<p-accordion (onOpen)="onTabOpen('z3')" (onClose)="onTabClose('z3')">
			<div class="scroll-indicator">
				<i class="fa fa-angle-right blink"></i>
				<i class="fa fa-angle-right blink"></i>
				<i class="fa fa-angle-right blink"></i>
			</div>
			<p-accordionTab [selected]="tabState?.z3 == null || tabState?.z3 == true ? true : false">
				<ng-template pTemplate="header">
					<div class="flex justify-content-between align-items-center w-100">
						<span>{{ 'txZeitKonto.bookings' | translate }}</span>
					</div>
				</ng-template>
				<section class="widget">
					<div class="widget-body">
						<div class="mt">
							<p-table
								[value]="myBuchung"
								styleClass="table table-hover table-responsive"
								[paginator]="true"
								[rows]="15"
								[responsive]="false"
								sortField="Datum"
								[sortOrder]="0">
								<ng-template pTemplate="header">
									<tr>
										<th style="min-width: 130px">{{ 'txZeitKonto.date' | translate }}</th>
										<th style="min-width: 80px">{{ 'txZeitKonto.begin' | translate }}</th>
										<th style="min-width: 80px">{{ 'txZeitKonto.end' | translate }}</th>
										<th>{{ 'txZeitKonto.debit' | translate }}</th>
										<th>{{ 'txZeitKonto.diff' | translate }}</th>
										<th>{{ 'txZeitKonto.ist' | translate }}</th>
										<th>{{ 'txZeitKonto.sum' | translate }}</th>
										<th style="min-width: 130px">{{ 'txZeitKonto.dailyInfo' | translate }}</th>
									</tr>
								</ng-template>
								<ng-template pTemplate="body" let-item>
									<tr [class.weekend]="isWeekend(item.Datum)" (click)="buchungsDate = item.Datum">
										<td>
											<span class="p-column-title">{{ 'txZeitKonto.date' | translate }}</span>
                                            <a [routerLink]="['/kalender', { date: item.Datum }]">
											    {{ item.Datum | date: 'EEE dd.MM.yyyy' }}
                                            </a>
										</td>
										<td>
											<span class="p-column-title">{{ 'txZeitKonto.begin' | translate }}</span>
											{{ item.beginnart }} {{ item.beginn | coTime }}
										</td>
										<td>
											<span class="p-column-title">{{ 'txZeitKonto.end' | translate }}</span>
											{{ item.endeart }} {{ item?.ende | coTime }}
										</td>
										<td>
											<span class="p-column-title">{{ 'txZeitKonto.debit' | translate }}</span>
											{{ item.soll }}
										</td>
										<td [style.color]="isNegative(item.Diff)">
											<span class="p-column-title">{{ 'txZeitKonto.diff' | translate }}</span>
											{{ item.Diff }}
										</td>
										<td [style.color]="isNegative(item.Summe)">
											<span class="p-column-title">{{ 'txZeitKonto.ist' | translate }}</span>
											{{ item.Summe }}
										</td>
										<td [style.color]="isNegative(item.bde_sum)">
											<span class="p-column-title">{{ 'txZeitKonto.sum' | translate }}</span>
											{{ item.bde_sum }}
										</td>
										<td [style.backgroundColor]="item.FehlzeitFarbe">
											<span class="p-column-title">{{ 'txZeitKonto.dailyInfo' | translate }}</span>
											{{ item.Tagesinfo }}
										</td>
									</tr>
								</ng-template>
							</p-table>
						</div>
					</div>
				</section>
			</p-accordionTab>
		</p-accordion>
	</div>
	<div class="col-12 xl:col-3">
		<p-accordion (onOpen)="onTabOpen('z1')" (onClose)="onTabClose('z1')" id="salden">
			<p-accordionTab [selected]="tabState?.z1 == null || tabState?.z1 == true ? true : false">
				<ng-template pTemplate="header">
					<div class="flex justify-content-between align-items-center w-100">
						<span>{{ 'txZeitKonto.balances' | translate }}</span>
						<div>
							<div *ngIf="reports$ | async as reports">
								<button
									mat-mini-fab
									color="primary"
									type="button"
									[disabled]="loadingReport"
									(click)="stopPropagation($event)"
									[matMenuTriggerFor]="reportMenu">
									<fa-icon *ngIf="!loadingReport" icon="file-pdf"></fa-icon>
									<fa-icon *ngIf="loadingReport" icon="spinner" [spin]="true" disabled></fa-icon>
								</button>
								<mat-menu #reportMenu="matMenu">
									<button *ngFor="let report of reports" (click)="downloadReport(report)" mat-menu-item>
										{{ report.report_name }}
									</button>
								</mat-menu>
							</div>
						</div>
					</div>
				</ng-template>
				<section class="widget">
					<div class="widget-body">
						<p-table [value]="myInfo" styleClass="table" id="balancesTable" [responsive]="false">
							<ng-template pTemplate="header">
								<tr>
									<th class="">{{ 'txZeitKonto.account' | translate }}</th>
									<th class="text-xs-right">{{ 'txZeitKonto.balance' | translate }}</th>
								</tr>
							</ng-template>
							<ng-template pTemplate="body" let-item>
								<tr>
									<td class="table-cell" [innerHTML]="item.konto"></td>
									<td class="table-cell text-xs-right justify-content-end" [innerHTML]="item.wert"></td>
								</tr>
							</ng-template>
						</p-table>
					</div>
				</section>
			</p-accordionTab>
		</p-accordion>
		<p-accordion
			*ngIf="myAuthService?.getSession()?.Workflow == 1"
			(onOpen)="onTabOpen('z2')"
			(onClose)="onTabClose('z2')">
			<p-accordionTab
				[header]="'txZeitKonto.requestBookings' | translate"
				[selected]="tabState?.z2 == null || tabState?.z2 == true ? true : false">
				<section class="widget">
					<tx-apply-booking [myFormType]="buchungForm" [myBuchungsDate]="buchungsDate"></tx-apply-booking>
				</section>
			</p-accordionTab>
		</p-accordion>
        <div *ngIf="userPermissions$ | async as Permissions">
            <div *ngIf="hasPermission(Permissions, has_message_center)">
                <div *ngTemplateOutlet="msgCenter"></div>
            </div>
        </div>
	</div><!--
    <div class="block pt-4 sm:hidden col-12">
        <div class="flex justify-content-center p-2">
            <button mat-raised-button (click)="showMessageCenter = true">
                <mat-icon>chat</mat-icon>
                Zum Message Center
            </button>
        </div>
    </div>
    <div *ngIf="showMessageCenter" class="bg-white fixed left-0 right-0 bottom-0" style="z-index: 99999; top: 65px;">
        <div>
            <div class="py-2 flex justify-content-center">
                <button mat-raised-button (click)="showMessageCenter = false">
                    <mat-icon>close</mat-icon>
                    Schließen
                </button>
            </div>
            <div *ngTemplateOutlet="msgCenter"></div>
        </div>
    </div>-->
</div>
<ng-template #msgCenter>
    <p-accordion (onOpen)="onTabOpen('z1')" (onClose)="onTabClose('z1')">
        <p-accordionTab
                [selected]="tabState?.z1 == null || tabState?.z1 == true ? true : false"
                [header] ="'Message Center'">
            <tx-messagecenter-client [fullHeight]="showMessageCenter"></tx-messagecenter-client>
        </p-accordionTab>
    </p-accordion>
</ng-template>
