import {
    Component,
    OnInit
} from '@angular/core';
import { passwordStrength } from 'check-password-strength';
import {
    TxSetting,
    TxUpdatePassword
} from '../../shared/interfaces/my-account';
import {
    merge,
    Observable,
    of,
    Subject,
    Subscription
} from 'rxjs';
import { TxApiService } from '../../shared/services/txapi.service';
import { TxSession } from '../../shared/interfaces/session';
import { AuthService } from '../../shared/services/auth.service';
import { GeoDataService } from '../../shared/services/geodata.service';
import sha1 from 'crypto-js/sha1';
import { AppConfigService } from '../../shared/services/appconfig.service';
import { TerminalService } from '../../shared/services/terminal.service';
import * as LocalForage from 'localforage';
import { ErrorloggerService } from '../../shared/services/errorlogger.service';
import { Store } from '@ngrx/store';
import { State } from '../../store/reducers';
import {
    getPermissions,
    getSession
} from '../../store/selectors/user.selectors';
import {
    LangChangeEvent,
    TranslateService
} from '@ngx-translate/core';
import {
    debounce,
    debounceTime,
    map,
    take,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import {
    addPermission,
    removePermission
} from '../../store/actions/user.actions';
import { CLOUD_MENU } from '../../store/entities/Permissions';
import { MatSlideToggleChange } from '@angular/material/slide-toggle/slide-toggle';
import { Router } from '@angular/router';
import {
    TxWorkflow,
    TxWorkflowRequest
} from '../../shared/interfaces/workflow';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

declare var Messenger: any;

@Component({
    selector: 'support-access-dialog',
    template: `
    <h2 mat-dialog-title>{{ 'tx-my-account.supportConfirmHeader' | translate }}</h2>
    <mat-dialog-content>
        <p>{{ 'tx-my-account.supportConfirmText' | translate }}</p>
        <div class="security-pin-container">
            <p class="security-info">{{ 'tx-my-account.securityPinInfo' | translate }}</p>
            <div class="security-pin">{{ securityPin }}</div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close>{{ 'general.no' | translate }}</button>
        <button mat-raised-button color="primary" [mat-dialog-close]="true">{{ 'general.yes' | translate }}</button>
    </mat-dialog-actions>
    `,
    styles: [`
        .security-pin-container {
            margin: 20px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .security-pin-label {
            font-weight: bold;
            margin-bottom: 8px;
        }
        .security-pin {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 4px;
            background-color: #f5f5f5;
            padding: 12px 24px;
            border-radius: 4px;
            border: 1px solid #ddd;
            color: #444;
            font-family: monospace;
        }
        .security-info {
            font-size: 14px;
            color: #444;
            font-weight: bold;
            text-align: center;
            margin-top: 12px;
        }
    `]
})
export class SupportAccessDialogComponent {
    securityPin: string;

    constructor(
        public dialogRef: MatDialogRef<SupportAccessDialogComponent>,
        private translateService: TranslateService
    ) {
        // Generate a random 6-digit PIN
        this.securityPin = this.generateRandomPin(6);
    }

    private generateRandomPin(length: number): string {
        const digits = '0123456789';
        let pin = '';
        for (let i = 0; i < length; i++) {
            pin += digits.charAt(Math.floor(Math.random() * digits.length));
        }
        return pin;
    }
}

@Component({
    selector: 'tx-my-account',
    templateUrl: './tx-my-account.component.html',
})
export class TxMyAccountComponent implements OnInit {
    active = true;
    buchungen = false;
    workflow = false;
    info = false;
    zeitkonto = false;
    teamleiter = false;
    kalender = false;
    session: TxSession;
    session$: Observable<TxSession>;
    geoDataEnabled: any;
    geoDataSubscription: Subscription;
    barcodeState = 0;
    terminalMode = false;
    pinLogin = false;
    fastLogout = false;
    myRequestQueue: LocalForage;

    passwordStrengthColorMap = ['#ff3636', '#d96941', '#e3f322', '#38a023'];

    passwordStrengthTextMap = ['Schwach', 'Okay', 'Gut', 'Stark'];
    passwordStrength = '';

    newPasswordStrength: Subject<string> = new Subject<string>();
    newPasswordStrength$: Observable<{ value: number; color: string; text: string }>;

    currentLang$: Observable<string>;

    permissions$: Observable<string[]>;

    displayCameraPopup = false;
    selectedCamera = null;
    scannedCode = '';
    displayAppCachePopup = false;
    pwSetting;

    public updateSuccess = false;
    public myUpdatePassword: TxUpdatePassword = new TxUpdatePassword('', '', '');

    constructor(
        private readonly router: Router,
        private myTxApiservice: TxApiService,
        private myAuthService: AuthService,
        private myGeoDataService: GeoDataService,
        private myConfigService: AppConfigService,
        public myTerminalService: TerminalService,
        private myErrorlogService: ErrorloggerService,
        private translateService: TranslateService,
        private store: Store<State>,
        private dialog: MatDialog
    ) {
        this.newPasswordStrength$ = this.newPasswordStrength.asObservable().pipe(
            debounceTime(200),
            map((pass) => {
                const strength = passwordStrength(pass);
                return {
                    value: (strength.id + 1) * (100 / 4),
                    color: this.passwordStrengthColorMap[strength.id],
                    text: this.passwordStrengthTextMap[strength.id],
                };
            })
        );
        this.permissions$ = store.select(getPermissions);
        this.myRequestQueue = LocalForage.createInstance({
            name: 'TxWebRequestQueue',
        });
        this.geoDataSubscription = this.myGeoDataService.isEnabled().subscribe((state) => {
            this.geoDataEnabled = state;
        });
        this.myTerminalService.terminalMode.subscribe((mode) => (this.terminalMode = mode));
        this.myTerminalService.pinLogin.subscribe((data) => (this.pinLogin = data));

        this.currentLang$ = merge(
            of(translateService.currentLang),
            translateService.onLangChange.asObservable().pipe(map((lang) => lang.lang))
        );

        this.session = myAuthService.getSession();
        this.session$ = store.select(getSession);

        let activeFrontpage: string;
        activeFrontpage = localStorage.getItem('Frontpage');
        switch (activeFrontpage) {
            case 'buchungen':
                this.buchungen = true;
                break;
            case 'info':
                this.info = true;
                break;
            case 'zeitkonto':
                this.zeitkonto = true;
                break;
            case 'workflow':
                this.workflow = true;
                break;
            case 'teamleiter':
                this.teamleiter = true;
                break;
            case 'kalender':
                this.kalender = true;
                break;
        }
        this.myConfigService.getBarcodeStatus().subscribe((state) => (this.barcodeState = state));

        this.myTerminalService.fastLogout.subscribe((enabled) => {
            this.fastLogout = enabled;
        });
    }

    getPwSetting() {
        let mySettingData: Observable<TxSetting>;
        const request = {
            settingName: 'PW_SEC_LEVEL'
        };

        mySettingData = this.myTxApiservice.callAPI('getSetting', request);
        mySettingData.subscribe(
            (response) => {
                this.pwSetting = response.settingValue;
            },
            (error) => {
                console.log('ERROR' + error);
            }
        );
    }

    validatePasswordStrength(password: string) {
        this.passwordStrength = 'Strong';
        if (this.pwSetting === 1) {
            if (password.length < 8) {
                this.passwordStrength = 'tx-my-account.passwordShort';
            }
        } else if (this.pwSetting === 2) {

            if (password.length < 8) {
                this.passwordStrength = 'tx-my-account.passwordShort';
            } else if (!/[A-Z]/.test(password)) {
                this.passwordStrength = 'tx-my-account.passwordUpper';
            } else if (!/[a-z]/.test(password)) {
                this.passwordStrength = 'tx-my-account.passwordUpper';
            } else if (!/[0-9]/.test(password)) {
                this.passwordStrength = 'tx-my-account.passwordNumber';
            } else if (!/[!@#$%^&*]/.test(password)) {
                this.passwordStrength = 'tx-my-account.passwordSpecial';
            }
        }
    }

    changeNewPassword() {
        this.validatePasswordStrength(this.myUpdatePassword.NewPasswordUndecoded);
        this.newPasswordStrength.next(this.myUpdatePassword.NewPasswordUndecoded);
    }

    setFrontpage(value: string) {
        localStorage.setItem('Frontpage', value);
    }

    enableSupportAccess() {
        const dialogRef = this.dialog.open(SupportAccessDialogComponent, {
            width: '450px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.session$.pipe(take(1)).subscribe(session => {
                    const request = {
                        sessionid: session.sessionid
                    };
        
                    this.myTxApiservice.callAPI('setSupportAccess', request).subscribe(
                        (response) => {
                            if (response.statuscode === 0) {
                                Messenger().post({
                                    message: response.statustext,
                                    type: 'success',
                                    showCloseButton: true,
                                    hideAfter: 5
                                });
                            } else {
                                Messenger().post({
                                    message: response.statustext || 'Fehler beim Aktivieren des Support-Zugriffs',
                                    type: 'error',
                                    showCloseButton: true,
                                    hideAfter: 5
                                });
                            }
                        },
                        (error) => {
                            console.error('Error activating support access:', error);
                            Messenger().post({
                                message: 'Fehler beim Aktivieren des Support-Zugriffs',
                                type: 'error',
                                showCloseButton: true,
                                hideAfter: 5
                            });
                        }
                    );
                });
            }
        });
    }

    updatePassword() {
        let myRequest: Observable<any>;
        let myResponse: any;
        this.myUpdatePassword.NewPassword = sha1(this.myUpdatePassword.NewPasswordUndecoded).toString();

        myRequest = this.myTxApiservice.callAPI('updatePassword', this.myUpdatePassword);
        myRequest.pipe(withLatestFrom(this.session$), take(1)).subscribe(
            ([myData, session]) => {
                myResponse = myData;
                this.active = false;
                this.updateSuccess = true;
                this.myUpdatePassword.NewPasswordUndecoded = '';
                this.myUpdatePassword.NewPasswordRepeat = '';
                let responseStatus: string;
                if (myResponse.statuscode === 0) {
                    responseStatus = 'success';
                    this.router.navigate(['/']);
                    setTimeout(() => {
                        this.myAuthService.logout();
                        location.reload();
                    }, 500);
                } else {
                    responseStatus = 'error';
                }
                Messenger().post({
                    message: myResponse.statustext,
                    type: responseStatus,
                    showCloseButton: true,
                    hideAfter: 5,
                });
                setTimeout(() => (this.active = true), 0);
            },
            (error) => {
                console.log('ERROR' + error);
                Messenger().post({
                    message: myResponse.error,
                    type: 'success',
                    showCloseButton: true,
                    hideAfter: 5,
                });
            }
        );
    }

    changeCloudAdmin(show = false) {
        if (show) {
            this.store.dispatch(addPermission(CLOUD_MENU));
        } else {
            this.store.dispatch(removePermission(CLOUD_MENU));
        }
    }

    changeLang(language: string = 'de') {
        this.translateService.use(language);
    }

    handleToggleChange($event: MatSlideToggleChange) {
        this.myGeoDataService.setGeoDataLocalStorage($event.checked);
    }

    handleTerminalPinModeChange($event: MatSlideToggleChange) {
        this.myTerminalService.setPinLogin($event.checked);
    }

    handleFastLogoutChange(event: MatSlideToggleChange) {
        this.myTerminalService.setFastLogout(event.checked);
    }

    updateAppCache() {
        try {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then((registrations) => {
                    // returns installed service workers

                    if (registrations.length) {
                        for (const registration of registrations) {
                            registration.unregister().then(() => {
                                caches.keys().then((names) => {
                                    for (const name of names) {
                                        caches.delete(name);
                                    }
                                });
                            });
                        }
                    }
                });
            } else {
                // clear web browser cache
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name);
                    }
                });
            }
        } catch ( e ) {
        } finally {
        }

        document.location.reload();
    }

    setBarcodeState(state: number) {
        this.myConfigService.setBarcodeStatus(state);
    }

    clearOfflineBookings() {
        this.myRequestQueue
            .clear()
            .then(() => {
                // Run this code once the database has been entirely deleted.
                console.log('Database is now empty.');
            })
            .catch((err) => {
                // This code runs if there were any errors
                this.myErrorlogService.handleError(err);
            });
    }

    ngOnInit() {
        this.getPwSetting();

        Messenger.options = {
            extraClasses: 'messenger-fixed messenger-on-top',
            theme: 'flat',
        };
    }
}
