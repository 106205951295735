import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { EmployeeEntity } from '../../../store/entities/EmployeeEntity';
import { MessengerService } from '../../../shared/services/messenger.service';
import { Store } from '@ngrx/store';
import { State } from '../../../store/reducers';
import {
	getAllTeamleaderEmployeeAssignments,
	getEmployees,
	getTeamleaderList,
	getTeamList,
} from '../../../store/selectors/personal-office.selectors';
import { TeamLeaderEntity } from '../../../store/entities/TeamLeaderEntity';
import { PersonalOfficeService } from '../../../shared/services/personal-office.service';
import { debounceTime, map, withLatestFrom, combineLatest as combineLatestOp, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { TeamEntity } from '../../../store/entities/TeamEntity';
import { EmployeeTeamleaderAssingmentEntity } from '../../../store/entities/EmployeeTeamleaderAssingmentEntity';
import { DndDropEvent } from 'ngx-drag-drop';
import { $t } from 'codelyzer/angular/styles/chars';
import { MatDialog } from '@angular/material/dialog';
import { TxCreateTeamLeaderDialogComponent } from './dialogs/tx-create-teamleader/tx-create-team-leader.dialog.component';
import { MatAccordion } from '@angular/material/expansion';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TxSetTeamleader } from '../../../shared/interfaces/session';
import { LoadTeamleaderListRequest } from '../../../store/actions/personal-office.actions';
import { SetTeamleader } from '../../../store/actions/teamleader.actions';

interface EmployeeFilter {
	teamleader: boolean;
	teamless: boolean;
	sort: {
		column: 'nr' | 'name' | 'team';
		dir: 'asc' | 'desc';
	} | null;
}

@Component({
	templateUrl: './tx-teamconfiguration.component.html',
	styleUrls: ['./tx-teamconfiguration.component.scss'],
})
export default class TxTeamconfigurationComponent implements OnInit, OnDestroy {
	constructor(
		private readonly service: PersonalOfficeService,
		private readonly messengerService: MessengerService,
		private readonly store: Store<State>,
		private readonly dialog: MatDialog
	) {
		this.Teams$ = this.store.select(getTeamList);

		this.Employees$ = this.store.select(getEmployees);

		this.AllTeamleaderAssingments$ = this.store.select(getAllTeamleaderEmployeeAssignments);

		// Initialize with expanded state from session or empty object
		this.isExpanded = this.loadExpandedStatesFromSession() || {};

		this.Teamleader$ = combineLatest([
			this.Search$.asObservable().pipe(debounceTime(200)),
			this.store.select(getTeamleaderList),
			this.store.select(getEmployees),
			this.AllTeamleaderAssingments$,
		]).pipe(
			map(([search, teamleader, employees, allAssignments]) => {
				// Sort team leaders by name
				const sortedTeamleader = teamleader.slice().sort((a, b) => a.name.localeCompare(b.name));

				// If no search term is provided, return all team leaders
				if (!search || search.length === 0) {
					return sortedTeamleader;
				}

				const searchLower = search.toLowerCase();

				// Filter all team leaders (by name, team leader, deputy and team members)
				return sortedTeamleader.filter((tl) => {
					// 1. Team leader name contains search text
					if (tl.name.toLowerCase().includes(searchLower)) {
						return true;
					}

					// 2. Team leader employee name contains search text
					const teamleaderEmployee = employees.find((e) => +e.persnr === tl.persnr);
					if (teamleaderEmployee) {
						const tlFullName = `${teamleaderEmployee.firstname || ''} ${
							teamleaderEmployee.lastname || ''
						}`.toLowerCase();
						if (tlFullName.includes(searchLower)) {
							return true;
						}
					}

					// 3. Deputy team leader name contains search text
					const deputyEmployee = employees.find((e) => +e.persnr === tl.deputy_persnr);
					if (deputyEmployee) {
						const deputyFullName = `${deputyEmployee.firstname || ''} ${deputyEmployee.lastname || ''}`.toLowerCase();
						if (deputyFullName.includes(searchLower)) {
							return true;
						}
					}

					// 4. Team members' names contain search text
					const teamAssignments = allAssignments[tl.id] || [];
					return teamAssignments.some((assignment) => {
						const employee = employees.find((e) => +e.persnr === assignment.persnr);
						if (employee) {
							const fullName = `${employee.firstname || ''} ${employee.lastname || ''}`.toLowerCase();
							return fullName.includes(searchLower);
						}
						return false;
					});
				});
			})
		);

		// Subscribe to teamleader changes to maintain expanded states
		this.teamleaderSubscription = this.Teamleader$.subscribe((teamleaders) => {
			// Ensure all teamleaders have an entry in isExpanded
			teamleaders.forEach((teamleader) => {
				if (this.isExpanded[teamleader.id] === undefined) {
					this.isExpanded[teamleader.id] = false;
				}
			});

			// Save the updated state
			this.saveExpandedStatesToSession();
		});

		this.FilteredEmployees$ = combineLatest([
			this.store.select(getEmployees),
			this.EmployeeSearch$.asObservable().pipe(debounceTime(200)),
			this.EmployeeFilterObservable$,
			this.AllTeamleaderAssingments$,
		]).pipe(
			withLatestFrom(this.Teams$),
			map(([[employees, search, filter, teamleaderAssingments], teams]) => {
				let ret = employees.filter((e) => {
					return (
						!(
							filter.teamless &&
							Object.values(teamleaderAssingments).find((tla) => tla.find((tl) => tl.persnr === +e.persnr))
						) && !(filter.teamleader && e.teamleader !== 1)
					);
				});
				if (filter.sort) {
					this.isSelectedEmployee = {};
					ret = ret.sort((a, b) => {
						let aElement = '';
						let bElement = '';
						switch (filter.sort.column) {
							case 'nr':
								aElement = a.persnr.toString();
								bElement = b.persnr.toString();
								return filter.sort.dir === 'asc' ? +aElement - +bElement : +bElement - +aElement;
							case 'name':
								aElement = `${a.firstname ?? ''}${a.lastname ?? ''}`;
								bElement = `${b.firstname ?? ''}${b.lastname ?? ''}`;
								break;
							case 'team':
								aElement = this.getTeamName(a.employee_id, employees, teams);
								bElement = this.getTeamName(b.employee_id, employees, teams);
								break;
							default:
								break;
						}
						return filter.sort.dir === 'asc' ? aElement.localeCompare(bElement) : bElement.localeCompare(aElement);
					});
				}
				if (!search || search.length === 0) {
					return ret;
				}
				this.isSelectedEmployee = {};
				return ret.filter((e) => {
					return (
						e.persnr.toString().includes(search) ||
						e.firstname?.toLowerCase().includes(search.toLowerCase()) ||
						e.lastname?.toLowerCase().includes(search.toLowerCase()) ||
						this.getTeamName(e.employee_id, employees, teams)?.toLowerCase().includes(search.toLowerCase())
					);
				});
			})
		);
	}

	// Session storage key for expanded states
	private readonly EXPANDED_STATE_KEY = 'teamleader-expanded-states';

	// Subscription to track and clean up
	private teamleaderSubscription: any;

	public isDisabled = false;
	public isExpanded: {
		[key: number]: boolean;
	} = {};
	public isSelectAll = false;
	public isSelectedEmployee: {
		[key: string]: boolean;
	} = {};

	public Employees$: Observable<EmployeeEntity[]>;
	public FilteredEmployees$: Observable<EmployeeEntity[]>;
	public Teamleader$: Observable<TeamLeaderEntity[]>;
	public AllTeamleaderAssingments$: Observable<{
		[key: number]: EmployeeTeamleaderAssingmentEntity[];
	}>;
	public Search$ = new BehaviorSubject<string>('');
	public EmployeeSearch$ = new BehaviorSubject<string>('');
	public EmployeeFilter$ = new BehaviorSubject<EmployeeFilter>({ teamleader: false, teamless: false, sort: null });
	public EmployeeFilterObservable$ = this.EmployeeFilter$.asObservable();
	public Teams$: Observable<TeamEntity[]>;

	public CurrentEmployee: EmployeeEntity = null;

	public IsEditMode: {
		[key: number]: boolean;
	} = {};

	@ViewChildren('teamleaderList') teamleaderList: QueryList<ElementRef>;
	@ViewChild(MatAccordion) accordion: MatAccordion;

	protected readonly $t = $t;

	// Lifecycle hook: component initialization
	ngOnInit() {
		// Load expanded states from session storage on component initialization
		const savedStates = this.loadExpandedStatesFromSession();
		if (savedStates) {
			this.isExpanded = savedStates;
		}
	}

	// Lifecycle hook: component destruction
	ngOnDestroy() {
		// Clean up subscription
		if (this.teamleaderSubscription) {
			this.teamleaderSubscription.unsubscribe();
		}
	}

	// Load expanded states from session storage
	private loadExpandedStatesFromSession(): { [key: number]: boolean } | null {
		try {
			const savedStates = sessionStorage.getItem(this.EXPANDED_STATE_KEY);
			return savedStates ? JSON.parse(savedStates) : null;
		} catch (error) {
			console.error('Error loading expanded states from session storage:', error);
			return null;
		}
	}

	// Save expanded states to session storage
	private saveExpandedStatesToSession() {
		try {
			sessionStorage.setItem(this.EXPANDED_STATE_KEY, JSON.stringify(this.isExpanded));
		} catch (error) {
			console.error('Error saving expanded states to session storage:', error);
		}
	}

	// Handle panel toggle event from template
	public onPanelToggle(teamleaderId: number, isExpanded: boolean) {
		this.isExpanded[teamleaderId] = isExpanded;
		this.saveExpandedStatesToSession();
	}

	public log(l: any) {
		console.log(l);
		return '';
	}

	public OnSelectAll(filtered: EmployeeEntity[]) {
		if (this.isSelectAll) {
			this.isSelectedEmployee = filtered
				.map((e) => e.persnr)
				.reduce((acc, cur) => {
					acc[cur] = true;
					return acc;
				}, {});
		} else {
			this.isSelectedEmployee = {};
		}
	}

	public OnEmployeeFilter(filter: string, currentFilter: EmployeeFilter) {
		switch (filter) {
			case 'teamleader':
				this.EmployeeFilter$.next({ ...currentFilter, teamleader: !currentFilter.teamleader });
				break;
			case 'teamless':
				this.EmployeeFilter$.next({ ...currentFilter, teamless: !currentFilter.teamless });
				break;
			default:
				if (filter.startsWith('sort:')) {
					const arr = filter.split(':');
					const column = arr[1] as 'nr' | 'name' | 'team';
					const dir = arr[2] as 'asc' | 'desc';
					this.EmployeeFilter$.next({ ...currentFilter, sort: { column, dir } });
				}
				break;
		}
	}

	public OnChangeCoLeaderActive(teamleader: TeamLeaderEntity, event: MatCheckboxChange) {
		// We need to set expanded state before service call to ensure it's maintained
		this.isExpanded[teamleader.id] = true;
		this.saveExpandedStatesToSession();

		// Use setTimeout to ensure this runs after the current event cycle
		setTimeout(() => {
			this.service.updateTeamleader({ ...teamleader, deputy_active: event.checked ? 1 : 0 });
		}, 0);
	}

	public OnDeleteCoLeader(teamleader: TeamLeaderEntity) {
		// We need to set expanded state before service call to ensure it's maintained
		this.isExpanded[teamleader.id] = true;
		this.saveExpandedStatesToSession();

		// Use setTimeout to ensure this runs after the current event cycle
		setTimeout(() => {
			this.service.updateTeamleader({ ...teamleader, deputy_persnr: 0 });
		}, 0);
	}

	public async OnCreateNewTeam(employees: EmployeeEntity[]) {
		const result: {
			name: string;
			teamleaderUser: number;
		} | null = await lastValueFrom(
			this.dialog
				.open(TxCreateTeamLeaderDialogComponent, {
					data: {
						title: 'Neue Teamleitergruppe erstellen',
						teamleaderUsers: employees.filter((e) => e.teamleader === 1),
					},
				})
				.afterClosed()
		);
		if (result) {
			this.service.createTeamleader(result.name, result.teamleaderUser);
		}
	}

	public isFilterActive(filter: EmployeeFilter) {
		return filter.teamleader;
	}

	public OnDeleteTeam(team: TeamLeaderEntity) {
		this.service.deleteTeamleader(team);
	}

	public OnSaveTeamName(team: TeamLeaderEntity) {
		const element: HTMLInputElement = document.getElementById(`teamleader-${team.id}-name`) as HTMLInputElement;
		this.service.updateTeamleader({ ...team, name: element.value });
		this.IsEditMode[team.id] = false;
	}

	public ReducePersonNr(data: EmployeeTeamleaderAssingmentEntity[]) {
		return data
			.reduce((acc, cur) => {
				acc.push(cur.persnr + '');
				return acc;
			}, [] as string[])
			.join(',');
	}

	getLabel(item: EmployeeEntity) {
		return `${item.lastname}, ${item.firstname}`;
	}

	getTeamleaderEmployeeAssignments(id: number): Observable<EmployeeTeamleaderAssingmentEntity[]> {
		return this.service.getTeamleaderEmployeeAssignments(id);
	}

	getEmployeeName(id: number, employees: EmployeeEntity[]) {
		const employee = employees.find((e) => +e.persnr === id);
		return `${employee?.firstname ?? ''} ${employee?.lastname ?? ''}`;
	}

	getTeamName(id, employees: EmployeeEntity[], teams: TeamEntity[]) {
		const num = +id;
		const teamId = employees.find((e) => +e.persnr === num)?.team_id;
		return teamId ? teams.find((t) => t.id === teamId)?.name ?? '' : '';
	}

	getTeamleaderAssingmentsCount(
		employee: EmployeeEntity,
		teamleaderAssingments: { [key: number]: EmployeeTeamleaderAssingmentEntity[] }
	): number {
		return Object.values(teamleaderAssingments).reduce(
			(acc, cur) => acc + (cur.find((c) => c.persnr === +employee.persnr) ? 1 : 0),
			0
		);
	}

	onChangeEditBooking(
		teamleader: TeamLeaderEntity,
		assignments: EmployeeTeamleaderAssingmentEntity[],
		assignment: EmployeeTeamleaderAssingmentEntity
	) {
		// Set expanded state before making changes
		this.isExpanded[teamleader.id] = true;
		this.saveExpandedStatesToSession();

		// Use setTimeout to ensure this runs after the current event cycle
		setTimeout(() => {
			this.service.setTeamleaderEmployeeAssignments(teamleader.id, [
				...assignments.filter((a) => a.persnr !== assignment.persnr),
				{
					EditAbsent: assignment.EditAbsent,
					EditBooking: (assignment.EditBooking + 1) % 2,
					persnr: assignment.persnr,
					teamleader_id: assignment.teamleader_id,
				},
			]);
		}, 0);
	}

	onChangeEditAbsent(
		teamleader: TeamLeaderEntity,
		assignments: EmployeeTeamleaderAssingmentEntity[],
		assignment: EmployeeTeamleaderAssingmentEntity
	) {
		// Set expanded state before making changes
		this.isExpanded[teamleader.id] = true;
		this.saveExpandedStatesToSession();

		// Use setTimeout to ensure this runs after the current event cycle
		setTimeout(() => {
			this.service.setTeamleaderEmployeeAssignments(teamleader.id, [
				...assignments.filter((a) => a.persnr !== assignment.persnr),
				{
					EditAbsent: (assignment.EditAbsent + 1) % 2,
					EditBooking: assignment.EditBooking,
					persnr: assignment.persnr,
					teamleader_id: assignment.teamleader_id,
				},
			]);
		}, 0);
	}

	getTeamleaderConnectToList(teamleader: TeamLeaderEntity[]) {
		return [
			...teamleader.map((t) => `teamleaderlist-${t.id}`),
			...teamleader.map((t) => `teamleaderlist-${t.id}-leader`),
			...teamleader.map((t) => `teamleaderlist-${t.id}-co-leader`),
		];
	}

	canDrop(data: string[], persnr: string) {
		return !data.find((d) => d === persnr);
	}

	onEnter(event: any) {
		console.log(event);
		(event.target as HTMLElement).classList.add('after:bg-green-400/60');
	}

	onDeleteFromList(
		teamleader: TeamLeaderEntity,
		list: EmployeeTeamleaderAssingmentEntity[],
		entity: EmployeeTeamleaderAssingmentEntity
	) {
		// Set expanded state before making changes
		this.isExpanded[teamleader.id] = true;
		this.saveExpandedStatesToSession();

		// Use setTimeout to ensure this runs after the current event cycle
		setTimeout(() => {
			const assignments = list.filter((tl) => tl.persnr !== entity.persnr);
			this.service.setTeamleaderEmployeeAssignments(teamleader.id, assignments);
		}, 0);
	}

	onLeave(employee: EmployeeEntity) {
		this.teamleaderList.forEach((l) => {
			l.nativeElement.classList.add(
				'after:absolute',
				'after:block',
				'after:-inset-1',
				'after:rounded',
				'after:border-gray-900',
				'after:border',
				'after:border-dashed'
			);
			const isTeamleader = !l.nativeElement.hasAttribute('data-employees');
			const attr = !isTeamleader
				? l.nativeElement.getAttribute('data-employees').split(',')
				: [l.nativeElement.getAttribute('data-persnr')];

			if (isTeamleader && employee.teamleader !== 1) {
				l.nativeElement.classList.add('after:bg-red-400/60');
				return;
			}

			if (!attr.find((p: string) => p === employee.persnr)) {
				l.nativeElement.classList.add('after:bg-gray-400/60');
			} else {
				l.nativeElement.classList.add('after:bg-red-400/60');
			}
		});
	}

	onEnd() {
		this.resetDnd();
	}

	public HasEmployeesSelected() {
		return Object.values(this.isSelectedEmployee).reduce((acc, cur) => acc || cur, false);
	}

	public CountEmployeesSelected() {
		return Object.values(this.isSelectedEmployee).reduce((acc, cur) => acc + (cur ? 1 : 0), 0);
	}

	async onAssignMultiple(teamleader: TeamLeaderEntity, assingments: EmployeeTeamleaderAssingmentEntity[]) {
		const persNrs = Object.keys(this.isSelectedEmployee).filter((k) => this.isSelectedEmployee[k]);
		const newAssingments = [...assingments];
		for (let persNr of persNrs) {
			if (!assingments.find((a) => a.persnr === +persNr)) {
				newAssingments.push({
					persnr: +persNr,
					teamleader_id: teamleader.id,
					EditAbsent: 0,
					EditBooking: 0,
				});
			}
		}

		if (newAssingments.length > assingments.length) {
			// Set expanded state before making changes
			this.isExpanded[teamleader.id] = true;
			this.saveExpandedStatesToSession();

			// Use setTimeout to ensure this runs after the current event cycle
			setTimeout(() => {
				this.service.setTeamleaderEmployeeAssignments(teamleader.id, newAssingments);
				this.isSelectedEmployee = {};
				this.isSelectAll = false;
			}, 0);
		}
	}

	async onDrop(event: DndDropEvent, dropLocation: null | 'list' | 'leader' | 'co-leader' = null) {
		const htmlElement = event.event.target as HTMLElement;
		const employee = event.data as EmployeeEntity;

		switch (dropLocation) {
			case 'list':
				if (this.canDrop(htmlElement.getAttribute('data-employees').split(','), event.data.persnr)) {
					const teamleader_id = +htmlElement.getAttribute('data-teamleader-id');
					const assignments = JSON.parse(htmlElement.getAttribute('data-team')) as EmployeeTeamleaderAssingmentEntity[];

					// Set expanded state before making changes
					this.isExpanded[teamleader_id] = true;
					this.saveExpandedStatesToSession();

					// Use setTimeout to ensure this runs after the current event cycle
					setTimeout(() => {
						assignments.push({
							persnr: +employee.persnr,
							teamleader_id: 0,
							EditAbsent: 0,
							EditBooking: 0,
						});
						this.service.setTeamleaderEmployeeAssignments(teamleader_id, assignments);
					}, 0);
				}
				break;
			case 'leader': {
				const teamleader: TeamLeaderEntity = JSON.parse(htmlElement.getAttribute('data-teamleader'));
				if (employee.teamleader === 1 && teamleader.persnr !== +employee.persnr) {
					// Set expanded state before making changes
					this.isExpanded[teamleader.id] = true;
					this.saveExpandedStatesToSession();

					// Use setTimeout to ensure this runs after the current event cycle
					setTimeout(() => {
						teamleader.persnr = +event.data.persnr;
						this.service.updateTeamleader(teamleader);
					}, 0);
				}
				break;
			}
			case 'co-leader': {
				const teamleader: TeamLeaderEntity = JSON.parse(htmlElement.getAttribute('data-teamleader'));
				if (event.data.teamleader === 1 && teamleader.deputy_persnr !== event.data.persnr) {
					// Set expanded state before making changes
					this.isExpanded[teamleader.id] = true;
					this.saveExpandedStatesToSession();

					// Use setTimeout to ensure this runs after the current event cycle
					setTimeout(() => {
						teamleader.deputy_persnr = +event.data.persnr;
						this.service.updateTeamleader(teamleader);
					}, 0);
				}
				break;
			}
			default:
				break;
		}
	}

	resetDnd() {
		this.teamleaderList.forEach((l) => {
			l.nativeElement.classList.remove(
				'after:absolute',
				'after:block',
				'after:-inset-1',
				'after:rounded',
				'after:border-gray-900',
				'after:border',
				'after:border-dashed',
				'after:bg-gray-400/60',
				'after:bg-red-400/60'
			);
		});
	}

	getDeputyState(employee: EmployeeEntity, teamleader: TeamLeaderEntity[]): TeamLeaderEntity | undefined {
		return teamleader.find((t) => t.persnr == +employee.persnr);
	}

	toggleDeputyState(teamLeader: TeamLeaderEntity) {
		// We need to set expanded state before dispatch to ensure it's maintained
		this.isExpanded[teamLeader.id] = true;
		this.saveExpandedStatesToSession();

		// Use setTimeout to ensure this runs after the current event cycle
		setTimeout(() => {
			this.store.dispatch(
				SetTeamleader({
					...teamLeader,
					deputy_active: teamLeader.deputy_active === 1 ? 0 : 1,
				} as TeamLeaderEntity)
			);
		}, 0);
	}

	// Modified accordion methods to save expanded states
	public openAll() {
		this.accordion.openAll();
		// Update all states to true and save
		this.Teamleader$.pipe(take(1)).subscribe((teamleaders) => {
			teamleaders.forEach((tl) => {
				this.isExpanded[tl.id] = true;
			});
			this.saveExpandedStatesToSession();
		});
	}

	public closeAll() {
		this.accordion.closeAll();
		// Update all states to false and save
		this.Teamleader$.pipe(take(1)).subscribe((teamleaders) => {
			teamleaders.forEach((tl) => {
				this.isExpanded[tl.id] = false;
			});
			this.saveExpandedStatesToSession();
		});
	}
}
