<div class="grid" *ngIf="session$ | async as Session">
    <div class="col-12 xl:col-6">
        <mat-card *ngIf="Session.reset_pw === 1" class="widget tx-warning-card mat-elevation-z0 overflow-hidden">
            <div class="pre-header">
                <i class="fa fa-exclamation-triangle"></i>
            </div>
            <mat-card-title>{{ 'tx-my-account.changePasswordWarningTitle' | translate }}</mat-card-title>
            <mat-card-content>
                {{ 'tx-my-account.changePasswordWarningText' | translate }}
            </mat-card-content>
        </mat-card>
        <mat-card class="widget mat-elevation-z0">
            <mat-card-title>{{ 'tx-my-account.changePassword' | translate }}</mat-card-title>
            <mat-card-content>
                <p>
                    {{ 'tx-my-account.ourRecommendation' | translate }}<br/>
                    {{ 'tx-my-account.ourRecommendationInfo' | translate }}
                </p>
                <p *ngIf="passwordStrength !== 'Strong'" class="text-danger">
                    {{ passwordStrength | translate}}
                </p>
                <form *ngIf="active" role="form" #passwortForm="ngForm" (ngSubmit)="updatePassword()">
                    <div [hidden]="!updateSuccess" class="alert alert-success alert-sm">
                        <span class="fw-semi-bold">{{ 'general.success' | translate }}
                            :</span> {{ 'tx-my-account.successPassword' | translate }}
                    </div>
                    <div class="form-group">
                        <label for="noac-new-Password">{{ 'tx-my-account.newPassword' | translate }}</label>
                        <input
                                id="noac-new-Password"
                                type="password"
                                class="form-control"
                                name="dontAutocomplete"
                                autocomplete="off"
                                [(ngModel)]="myUpdatePassword.NewPasswordUndecoded"
                                (ngModelChange)="changeNewPassword()"
                                required
                                validateEqual="dontAutocompleteConfirm"
                                reverse="true"
                                #password="ngModel"/>
                        <div *ngIf="newPasswordStrength$ | async as strength" style="margin-top: 0.5rem">
                            <tx-progressbar
                                    [height]="12"
                                    [text]="strength.text"
                                    [value]="strength.value"
                                    [color]="strength.color"></tx-progressbar>
                        </div>
                        <small [hidden]="password.valid || (password.pristine && !passwortForm.submitted)"
                               class="text-danger">
                            {{ 'tx-my-account.needPassword' | translate }}
                        </small>
                    </div>
                    <div class="form-group">
                        <label for="noac-new-Password-Repeat">{{ 'tx-my-account.repeatPassword' | translate }}</label>
                        <input
                                id="noac-new-Password-Repeat"
                                type="password"
                                class="form-control"
                                name="dontAutocompleteConfirm"
                                autocomplete="off"
                                [(ngModel)]="myUpdatePassword.NewPasswordRepeat"
                                required
                                validateEqual="dontAutocomplete"
                                reverse="false"
                                #confirmPassword="ngModel"/>
                        <small
                                [hidden]="confirmPassword.valid || (confirmPassword.pristine && !passwortForm.submitted)"
                                class="text-danger">
                            {{ 'tx-my-account.identicalPassword' | translate }}
                        </small>
                    </div>
                    <button type="submit" class="btn btn-primary btn-block" [disabled]="passwortForm.invalid || passwordStrength !== 'Strong'">
                        {{ 'tx-my-account.setPassword' | translate }}
                    </button>
                </form>
                <hr class="my-3">
                <mat-card-title>{{ 'tx-my-account.supportHeader' | translate }}</mat-card-title>
                <p>
                    {{ 'tx-my-account.explanation' | translate }}
                </p>
                <div class="mt-3">
                    <button mat-stroked-button color="primary" class="w-100" (click)="enableSupportAccess()">
                        {{ 'tx-my-account.support' | translate }}
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-12 xl:col-6">
        <mat-card class="widget mat-elevation-z0">
            <mat-card-title>{{ 'tx-my-account.chooseFrontpage' | translate }}</mat-card-title>
            <mat-card-content class="widget-body">
                <div class="btn-group" data-toggle="buttons">
                    <label class="btn btn-primary" [class.active]="buchungen" (click)="setFrontpage('buchungen')">
                        <input type="radio" name="startsite"/> {{ 'tx-my-account.timeRegistration' | translate }}
                    </label>
                    <label class="btn btn-primary" [class.active]="zeitkonto" (click)="setFrontpage('zeitkonto')">
                        <input type="radio" name="startsite"/> {{ 'tx-my-account.timeAccount' | translate }}
                    </label>
                    <label class="btn btn-primary" [class.active]="workflow" (click)="setFrontpage('workflow')">
                        <input type="radio" name="startsite"/> {{ 'tx-my-account.Workflow' | translate }}
                    </label>
                    <label
                            *ngIf="Session?.team_leader == 1"
                            class="btn btn-primary"
                            [class.active]="teamleiter"
                            (click)="setFrontpage('teamleiter')">
                        <input type="radio" name="startsite"/> {{ 'tx-my-account.teamLeader' | translate }}
                    </label>
                    <label
                            *ngIf="Session?.calendar == 1"
                            class="btn btn-primary"
                            [class.active]="kalender"
                            (click)="setFrontpage('kalender')">
                        <input type="radio" name="startsite"/> {{ 'tx-my-account.calender' | translate }}
                    </label>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="widget mat-elevation-z0">
            <mat-card-title>{{ 'tx-my-account.personalSettings' | translate }}</mat-card-title>

            <mat-card-content>
                <section>
                    <header>{{ 'tx-my-account.fastLogout' | translate }}</header>

                    <mat-slide-toggle color="primary" [(ngModel)]="fastLogout" (change)="handleFastLogoutChange($event)"
                    >

                    </mat-slide-toggle>
                </section>
                <hr/>

                <section>
                    <header>{{ 'tx-my-account.geodataActive' | translate }}</header>
                    <div class="widget-body">
                        <mat-slide-toggle [(ngModel)]="geoDataEnabled" color="primary"
                                          (change)="handleToggleChange($event)">
                            {{ 'tx-my-account.geodataAreActive' | translate }}?
                        </mat-slide-toggle>
                    </div>
                </section>
                <hr/>

                <section *ngIf="terminalMode">
                    <header>{{ 'tx-my-account.terminalPin' | translate }}</header>
                    <div class="widget-body">
                        <mat-slide-toggle [(ngModel)]="pinLogin" color="primary"
                                          (change)="handleTerminalPinModeChange($event)">
                            {{ 'tx-my-account.modeActive' | translate }}?
                        </mat-slide-toggle>
                    </div>
                </section>
                <hr/>

                <section *ngIf="terminalMode">
                    <header>{{ 'tx-my-account.qrCodeMode' | translate }}</header>

                    <div class="widget-body">
                        <div class="mb-2 flex flex-justify-content-space-between">
                            <mat-slide-toggle
                                    [checked]="myTerminalService.qrMode | async"
                                    (change)="myTerminalService.setQrMode($event.checked ? 1 : 0)"
                                    color="primary">
                                {{ 'tx-my-account.qrCodeModeOn' | translate }}
                            </mat-slide-toggle>
                            <button
                                    mat-flat-button
                                    class="ml-2"
                                    color="primary"
                                    [disabled]="!(myTerminalService.qrMode | async)"
                                    (click)="displayCameraPopup = true">
                                {{ 'tx-my-account.chooseCamera' | translate }}
                                <fa-icon icon="camera"></fa-icon>
                            </button>
                        </div>
                        <p-dialog
                                [(visible)]="displayCameraPopup"
                                [responsive]="true"
                                [header]="'tx-my-account.scanBarCode' | translate"
                                [blockScroll]="true"
                                [modal]="true"
                                appendTo="body">
                            <tx-code-scanner
                                    *ngIf="displayCameraPopup"
                                    [qrScan]="true"
                                    [scannerEnabled]="true"
                                    [configMode]="true"
                                    [enableDeviceSelector]="true"
                                    [selectedDevice]="myTerminalService.qrQrCamera | async"
                                    (select)="selectedCamera = $event"
                                    (scanResult)="scannedCode = $event"></tx-code-scanner>
                            <div class="h4 text-green-400">{{ scannedCode }}</div>
                            <button
                                    class="mt-1 w-100"
                                    mat-raised-button
                                    color="primary"
                                    [disabled]="!selectedCamera"
                                    (click)="myTerminalService.setQrCamera(selectedCamera); displayCameraPopup = false">
                                {{ 'general.save' | translate }}
                            </button>
                        </p-dialog>

                        <div>
                            <mat-slide-toggle
                                    color="primary"
                                    [checked]="myTerminalService.qrQrPermanentCamera | async"
                                    (change)="myTerminalService.setQrPermanentCamera($event.checked ? 1 : 0)">
                                {{ 'tx-my-account.cameraPermanentlyOn' | translate }}
                            </mat-slide-toggle>
                        </div>
                        <div>
                            <mat-slide-toggle
                                    color="primary"
                                    [checked]="myTerminalService.qrQrPermanentCameraHidden | async"
                                    (change)="myTerminalService.setQrPermanentCameraHidden($event.checked ? 1 : 0)"
                                    [disabled]="!(myTerminalService.qrQrPermanentCamera | async)">
                                {{ 'tx-my-account.cameraPermanentlyHidden' | translate }}
                            </mat-slide-toggle>
                        </div>
                        <div>
                            <mat-slide-toggle
                                    color="primary"
                                    [checked]="myTerminalService.qrQrCameraPositionConfig | async"
                                    (change)="myTerminalService.setQrCameraPositionConfig($event.checked ? 1 : 0)"
                                    [disabled]="!(myTerminalService.qrQrPermanentCamera | async)">
                                {{ 'tx-my-account.cameraPermanentlyMove' | translate }}
                            </mat-slide-toggle>
                        </div>
                    </div>
                </section>
            </mat-card-content>
        </mat-card>

        <mat-card class="widget mat-elevation-z0" *ngIf="currentLang$ | async as currentLang">
            <mat-card-title>{{ 'tx-my-account.chooseLanguage' | translate }}</mat-card-title>
            <mat-card-content class="widget-body">
                <div class="btn-group" data-toggle="buttons">
                    <label class="btn btn-primary" [class.active]="currentLang === 'de'" (click)="changeLang('de')">
                        <input type="radio" name="startsite"/> {{ 'navbar.lang.german' | translate }}
                    </label>
                    <label class="btn btn-primary" [class.active]="currentLang === 'en'" (click)="changeLang('en')">
                        <input type="radio" name="startsite"/> {{ 'navbar.lang.english' | translate }}
                    </label>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="widget mat-elevation-z0">
            <mat-card-title>{{ 'tx-my-account.barcodeSettings' | translate }}</mat-card-title>
            <mat-card-content class="widget-body">
                <div class="btn-group" data-toggle="buttons">
                    <label class="btn btn-primary" [class.active]="barcodeState == 0" (click)="setBarcodeState(0)">
                        <input type="radio" name="barcode"/> {{ 'tx-my-account.barcodeOff' | translate }}
                    </label>
                    <label class="btn btn-primary" [class.active]="barcodeState == 1" (click)="setBarcodeState(1)">
                        <input type="radio" name="barcode"/> {{ 'tx-my-account.barcodeTotal' | translate }}
                    </label>
                    <label class="btn btn-primary" [class.active]="barcodeState == 2" (click)="setBarcodeState(2)">
                        <input type="radio" name="barcode"/> {{ 'tx-my-account.barcodeSeparated' | translate }}
                    </label>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="widget mat-elevation-z0">
            <mat-card-title>{{ 'tx-my-account.emptyOfflineBookings' | translate }}</mat-card-title>
            <mat-card-content>
                <p class="p-1 bg-attention text-orange-500">
                    {{ 'tx-my-account.emptyOfflineBookingsInfo' | translate }}
                </p>
                <button
                        pButton
                        type="button"
                        class="btn-primary"
                        [label]="'tx-my-account.emptyOfflineBookings' | translate"
                        (click)="clearOfflineBookings()"></button>
            </mat-card-content>
        </mat-card>

        <mat-card class="widget mat-elevation-z0">
            <mat-card-title>{{ 'tx-my-account.applicationCache' | translate }}</mat-card-title>
            <mat-card-content>
                <p>{{ 'tx-my-account.warningApp' | translate }}</p>
                <button
                        pButton
                        type="button"
                        class="btn-primary"
                        [label]="'tx-my-account.renewCache' | translate"
                        (click)="displayAppCachePopup = true"></button>
                <p-dialog
                        [(visible)]="displayAppCachePopup"
                        [responsive]="true"
                        [header]="'tx-my-account.renewCache' | translate"
                        [blockScroll]="true"
                        [modal]="true"
                        appendTo="body">
                    <p>{{ 'tx-my-account.warningApp' | translate }}</p>
                    <button
                            class="mt-1 w-100"
                            mat-raised-button
                            color="primary"
                            (click)="updateAppCache()">
                        {{ 'tx-my-account.renewCache' | translate }}
                    </button>
                </p-dialog>
            </mat-card-content>
        </mat-card>
    </div>
</div>
