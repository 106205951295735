import { Injectable } from '@angular/core';
import {
    BehaviorSubject,
    Observable,
    of
} from 'rxjs';
import {
    map,
    tap
} from 'rxjs/operators';

export interface TabStates {
	w1: boolean;
	w2: boolean;
	w3: boolean;
	w4: boolean;
	z1: boolean;
	z2: boolean;
	z3: boolean;
	z4: boolean;
	z5: boolean;
	b1: boolean;
	b2: boolean;
	b3: boolean;
	b4: boolean;
	bde1: boolean;
	bde2: boolean;
	bde3: boolean;
	bde4: boolean;
	bde5: boolean;
	pze1: boolean;
	t1: boolean;
	t2: boolean;
	t3: boolean;
	t4: boolean;
	dayView: boolean;
	us1: boolean;
    pr1:boolean;
}

@Injectable()
export class TabStateService {

    private tabStates$ = new BehaviorSubject<TabStates>(null);
    public TabStates$ = this.tabStates$.asObservable().pipe(
        map((tabStates) => {
            return Object.keys(tabStates).reduce((acc, key) => {
                acc[key] = tabStates[key] === 1;
                return acc;
            }, {} as TabStates);
        }),
    );

	constructor() {
        this.tabStates$.next(this.getTabState());
    }

	public onTabOpen(name: string) {
		let existingTabEntries = JSON.parse(localStorage.getItem('tabActives'));
		if (existingTabEntries == null) {
			existingTabEntries = {};
		}

		existingTabEntries[name] = 1;
		localStorage.setItem('tabActives', JSON.stringify(existingTabEntries));

        this.tabStates$.next(existingTabEntries);
	}

	public onTabClose(name: string) {
		let existingTabEntries = JSON.parse(localStorage.getItem('tabActives'));
		if (existingTabEntries == null) {
			existingTabEntries = {};
		}

		existingTabEntries[name] = 0;
		localStorage.setItem('tabActives', JSON.stringify(existingTabEntries));

        this.tabStates$.next(existingTabEntries);
	}

    public getTabState(name: string = null) {
        let existingTabEntries = JSON.parse(localStorage.getItem('tabActives'));
        if (existingTabEntries == null) {
            existingTabEntries = {};
        }
        if(name) {
            return existingTabEntries[name] ?? true;
        }
        return existingTabEntries;
    }
}
