import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import {
    TxUpdateWorkflowRequest,
    TxWfCalendar,
    TxWorkflow,
    TxWorkflowCalendarProtokoll,
    TxWorkflowCalendarRequest,
    TxWorkflowRequest,
} from '../../shared/interfaces/workflow';
import { TxApiService } from '../../shared/services/txapi.service';
import { TabStates, TabStateService } from '../../shared/services/tabstate.service';
import { WorkflowService } from '../../shared/services/workflow.service';
import { NavigationEnd, Router } from '@angular/router';
import { HeartbeatService } from '../../shared/services/heartbeat.service';
import { filter } from 'rxjs/operators';

declare let Messenger: any;
declare let jQuery: any;

@Component({
    selector: 'tx-txworkflow',
    templateUrl: './txworkflow.component.html',
    providers: [TabStateService],
    encapsulation: ViewEncapsulation.None,
})
export class TxWorkflowComponent implements OnInit, OnDestroy {
    tabState: TabStates;

    buchungForm = 'Buchung';
    antragForm = 'Antrag';

    Workflow;
    myWorkflowCalendar: Array<TxWfCalendar>;

    myWorkflowHasData = false;

    // Track router subscription for proper cleanup
    private routerSubscription: Subscription;

    // Flag to track if initial data load has happened
    private initialDataLoaded = false;

    constructor(
        private myTxApiservice: TxApiService,
        private myTabStateService: TabStateService,
        private router: Router,
        private hearbeatService: HeartbeatService
    ) {}

    getWorkflow() {
        let myWorkflowData: Observable<TxWorkflow>;
        const myWorkflowRequestData = new TxWorkflowRequest();
        myWorkflowRequestData.from = '2010-02-01T04:58:21';
        myWorkflowRequestData.to = '2026-12-08T04:58:21';

        myWorkflowData = this.myTxApiservice.callAPI('getWorkflow', myWorkflowRequestData);
        myWorkflowData.subscribe(
            (response) => {
                this.Workflow = response.Workflows;
                if (this.Workflow.length > 0) {
                    this.myWorkflowHasData = true;
                }
            },
            (error) => {
                console.log('ERROR' + error);
            }
        );
    }

    getWorkflowCalendar(clear=true) {
        // Prevent unnecessary API calls if data is already loaded and clear=false
        if (!clear && this.myWorkflowCalendar) {
            return;
        }

        let myWorkflowCalendarData: Observable<TxWorkflowCalendarProtokoll>;
        const myWorkflowCalendarRequestData = new TxWorkflowCalendarRequest();
        myWorkflowCalendarRequestData.full_year = 0;

        if(clear){
            this.myWorkflowCalendar = null;
        }
        myWorkflowCalendarData = this.myTxApiservice.callAPI('getWfCalendar', myWorkflowCalendarRequestData);
        myWorkflowCalendarData.subscribe(
            (responseData) => {
                this.myWorkflowCalendar = responseData.WfCalendar;
            },
            (error) => {
                console.log('ERROR' + error);
            }
        );
    }

    onDeleteClick(aWfRow: any) {
        this.updateWorkflow(aWfRow, 100); // 100=löschen
        this.Workflow = this.Workflow.filter((elem) => elem !== aWfRow);
    }

    updateFromComponent() {
        setTimeout(() => {
            this.getWorkflow();
        }, 50);
    }

    onTabOpen(name) {
        this.myTabStateService.onTabOpen(name);
        switch (name) {
            case 'w1':
                this.watchWorkflowCalendar();
                break;
            case 'w4':
                this.watchWorkflow();
                break;
        }
    }

    onTabClose(name) {
        this.myTabStateService.onTabClose(name);
        switch (name) {
            case 'w1':
                this.unwatchWorkflowCalendar();
                break;
            case 'w4':
                this.unwatchWorkflow();
                break;
        }
    }

    hoverBoth($event: MouseEvent) {
        if ($event.type === 'mouseenter') {
            const prev = $event.target as Element;
            prev.previousElementSibling.classList.add('hover');
        } else {
            document.querySelectorAll('.bb-0').forEach((el) => el.classList.remove('hover'));
        }
    }

    updateWorkflow(aWfRow, status) {
        const aWfRequest: TxUpdateWorkflowRequest = new TxUpdateWorkflowRequest(aWfRow.id, status, null); // 100=löschen

        let apiAnfrage: Observable<TxUpdateWorkflowRequest>;
        let myResponse: any;

        apiAnfrage = this.myTxApiservice.callAPI('updateWorkflow', aWfRequest);

        apiAnfrage.subscribe(
            (mySessionData) => {
                myResponse = mySessionData;
                let responseStatus: string;
                if (myResponse.statuscode === 0) {
                    responseStatus = 'success';
                } else {
                    responseStatus = 'error';
                }
                Messenger().post({
                    message: myResponse.statustext,
                    type: responseStatus,
                    showCloseButton: true,
                    hideAfter: 5,
                });
            },
            (error) => {
                console.log('ERROR' + error);
                Messenger().post({
                    message: 'ERROR' + error,
                    type: 'error',
                    showCloseButton: true,
                });
            }
        );
    }

    onConfirmClick(aWfRow: any) {
        this.Workflow = this.Workflow.filter((elem) => elem !== aWfRow);
        this.updateWorkflow(aWfRow, 101); // 101=confirm
    }

    onApproveClick(aWfRow: any) {
        this.Workflow = this.Workflow.filter((elem) => elem !== aWfRow);
        this.updateWorkflow(aWfRow, 200); // 101=confirm
    }

    onDeclineClick(aWfRow: any) {
        this.Workflow = this.Workflow.filter((elem) => elem !== aWfRow);
        this.updateWorkflow(aWfRow, 201); // 101=confirm
    }

    checkBisData(bisData: any) {
        return bisData !== '';
    }

    refresh() {
        // Only refresh if not initial data load
        if (this.initialDataLoaded) {
            this.getWorkflowCalendar();
            this.getWorkflow();
        }
    }

    ngOnInit(): void {
        this.tabState = this.myTabStateService.getTabState();

        // Load data only once at initialization
        this.initialLoad();

        // Subscribe to router events but avoid duplicate calls
        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd &&
                event.url.indexOf('workflow') > -1)
        ).subscribe(() => {
            // Only refresh if coming from a different route
            console.log('Router navigation to workflow route');
            this.refresh();
        });

        this.watchWorkflow();
    }

    // New method to handle initial data loading
    private initialLoad() {
        this.getWorkflow();
        this.getWorkflowCalendar();
        this.initialDataLoaded = true;
    }

    public watchWorkflow() {
        if (this.myTabStateService.getTabState('w4')) {
            this.hearbeatService.addToHeartbeat('workflow', () => {
                this.getWorkflow();
            }, 60)
        }
    }

    public unwatchWorkflow() {
        this.hearbeatService.removeFromHeartbeat('workflow');
    }

    public watchWorkflowCalendar() {
        /* TODO: finish workflow calendar update in next version
        if (this.myTabStateService.getTabState('w1')) {
            this.hearbeatService.addToHeartbeat('workflowCalendar', () => {
                // Pass false to avoid clearing existing data if already loaded
                this.getWorkflowCalendar(false);
            }, 120)
        }
        */
    }

    public unwatchWorkflowCalendar() {
        /* TODO: finish workflow calendar update in next version
        this.hearbeatService.removeFromHeartbeat('workflowCalendar');
         */
    }

    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }

        this.unwatchWorkflow();
        this.unwatchWorkflowCalendar();
    }
}
