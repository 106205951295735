import {
    AfterViewInit,
    Component,
    ElementRef,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { TxApiService } from '../../../shared/services/txapi.service';
import { Subscription } from 'rxjs';
import {
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Tooltip,
    ChartDataset, // falls benötigt
} from 'chart.js';
import { FormControl } from '@angular/forms';

// Register Chart.js components
Chart.register(
    BarController,
    LineController,
    BarElement,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Tooltip,
    Legend
);

interface TimeAccountSum {
    year: number;
    month: number;
    target_minutes: number;
    worked_minutes: number;
    difference_minutes: number;
    AbsenceList: Array<{
        id: number;
        name: string;
        day_count: number;
        color: string;
    }>;
}

interface AbsenceType {
    id: number;
    name: string;
    totalDays: number;
    color: string;
}

// Hier erweitern wir das ChartDataset um unsere Eigenschaft "absenceId"
interface AbsenceChartDataset extends ChartDataset<'bar', number[]> {
    absenceId: number;
}

@Component({
    selector: 'tx-time-sums-chart',
    templateUrl: './tx-time-sums-chart.component.html',
    styleUrls: ['./tx-time-sums-chart.component.scss']
})
export class TxTimeSumsChartComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    @ViewChild('chartCanvas') chartCanvas: ElementRef;
    @ViewChild('absenceChartCanvas') absenceChartCanvas: ElementRef;

    timeRangeOptions: { label: string, value: number }[] = [
        { label: '6 Monate', value: 6 },
        { label: '12 Monate', value: 12 },
        { label: '15 Monate', value: 15 }
    ];

    selectedTimeRange = new FormControl(this.timeRangeOptions[1].value);

    timeAccountSums: TimeAccountSum[] = [];
    absenceTypes: AbsenceType[] = [];
    loading = false;
    chart: Chart;
    absenceChart: Chart;

    // Für die Filterung im Zeit-Chart
    selectedDataset: 'target' | 'worked' | 'difference' | null = null;
    // Für die Filterung im Abwesenheits-Chart speichern wir die aktuell ausgewählte Abwesenheits-ID
    selectedAbsenceDataset: number | null = null;

    private subscriptions = new Subscription();

    constructor(private txApiService: TxApiService) {}

    ngOnInit(): void {
        this.selectedTimeRange.valueChanges.subscribe(() => {
            this.fetchData();
        });
        this.fetchData();
    }

    ngAfterViewInit(): void {
        if (this.timeAccountSums.length > 0) {
            this.createChart();
            this.createAbsenceChart();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['timeAccountSums'] && !changes['timeAccountSums'].firstChange) {
            if (this.chart) {
                this.updateChart();
            }
            if (this.absenceChart) {
                this.updateAbsenceChart();
            }
        }
    }

    ngOnDestroy(): void {
        if (this.chart) {
            this.chart.destroy();
        }
        if (this.absenceChart) {
            this.absenceChart.destroy();
        }
        this.subscriptions.unsubscribe();
    }

    fetchData(): void {
        this.loading = true;
        const endDate = new Date();
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - this.selectedTimeRange.value);

        const request = {
            von: this.formatDate(startDate),
            bis: this.formatDate(endDate)
        };

        this.subscriptions.add(
            this.txApiService.callAPI('getDashboarTimeAccSums', request).subscribe(
                (response) => {
                    if (response && response.TimeAccountSumList) {
                        this.timeAccountSums = response.TimeAccountSumList;
                        this.absenceTypes = this.calculateAbsenceTypes();
                        this.createChart();
                        this.createAbsenceChart();
                    }
                    this.loading = false;
                },
                (error) => {
                    console.error('Error fetching time account data:', error);
                    this.loading = false;
                }
            )
        );
    }

    private formatDate(date: Date): string {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}T00:00:00`;
    }

    private createChart(): void {
        if (!this.chartCanvas) {
            return;
        }
        if (this.chart) {
            this.chart.destroy();
        }
        const ctx = this.chartCanvas.nativeElement.getContext('2d');
        const sortedData = [...this.timeAccountSums].sort((a, b) => (a.year * 100 + a.month) - (b.year * 100 + b.month));
        const labels = sortedData.map(item => {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
            return `${monthNames[item.month - 1]} ${item.year}`;
        });
        const targetHours = sortedData.map(item => this.minutesToHours(item.target_minutes));
        const workedHours = sortedData.map(item => this.minutesToHours(item.worked_minutes));
        const diffHours = sortedData.map(item => this.minutesToHours(item.difference_minutes));

        this.chart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Soll (Stunden)',
                        data: targetHours,
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'Ist (Stunden)',
                        data: workedHours,
                        backgroundColor: 'rgba(75, 192, 192, 0.5)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1
                    },
                    {
                        label: 'Differenz (Stunden)',
                        data: diffHours,
                        backgroundColor: 'rgba(255, 99, 132, 1)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        tension: 0.3,
                        borderWidth: 1,
                        type: 'line',
                        fill: true
                    }
                ]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        title: { display: true, text: 'Stunden' }
                    },
                    x: {
                        title: { display: true, text: 'Monat' }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const label = context.dataset.label || '';
                                const value = typeof context.parsed.y === 'number' ? context.parsed.y.toFixed(2) : context.parsed.y;
                                return `${label}: ${value}`;
                            }
                        }
                    }
                }
            }
        });
    }

    private updateChart(): void {
        if (!this.chart) {
            this.createChart();
            return;
        }
        const sortedData = [...this.timeAccountSums].sort((a, b) => (a.year * 100 + a.month) - (b.year * 100 + b.month));
        const labels = sortedData.map(item => {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
            return `${monthNames[item.month - 1]} ${item.year}`;
        });
        const targetHours = sortedData.map(item => this.minutesToHours(item.target_minutes));
        const workedHours = sortedData.map(item => this.minutesToHours(item.worked_minutes));
        const diffHours = sortedData.map(item => this.minutesToHours(item.difference_minutes));

        this.chart.data.labels = labels;
        this.chart.data.datasets[0].data = targetHours;
        this.chart.data.datasets[1].data = workedHours;
        this.chart.data.datasets[2].data = diffHours;
        this.chart.data.datasets[2].backgroundColor = diffHours.map(value =>
            value < 0 ? 'rgba(255, 99, 132, 0.5)' : 'rgba(75, 192, 192, 0.5)'
        );
        this.chart.data.datasets[2].borderColor = diffHours.map(value =>
            value < 0 ? 'rgba(255, 99, 132, 1)' : 'rgba(75, 192, 192, 1)'
        );
        this.chart.update();
    }

    private createAbsenceChart(): void {
        if (!this.absenceChartCanvas) {
            return;
        }
        if (this.absenceChart) {
            this.absenceChart.destroy();
        }
        const ctx = this.absenceChartCanvas.nativeElement.getContext('2d');
        const sortedData = [...this.timeAccountSums].sort((a, b) => (a.year * 100 + a.month) - (b.year * 100 + b.month));
        const labels = sortedData.map(item => {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
            return `${monthNames[item.month - 1]} ${item.year}`;
        });

        const absenceTypes = this.absenceTypes;
        // Erstelle Datensätze für jeden Abwesenheitstyp und cast diese als AbsenceChartDataset
        const datasets: AbsenceChartDataset[] = absenceTypes.map(absenceType => {
            const isSollTage = absenceType.name.includes('Soll-Tage');
            const isIstTage = absenceType.name.includes('Ist-Tage');
            const data = sortedData.map(monthData => {
                // Add null check for AbsenceList before calling find
                const absenceEntry = monthData.AbsenceList && monthData.AbsenceList.find(abs => abs.id === absenceType.id);
                return absenceEntry ? absenceEntry.day_count : null;
            });
            return {
                label: absenceType.name,
                data: data,
                backgroundColor: absenceType.color + '80',
                borderColor: absenceType.color,
                borderWidth: 1,
                skipNull: true,
                hidden: isSollTage || isIstTage,
                absenceId: absenceType.id
            } as AbsenceChartDataset;
        });

        this.absenceChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: { stepSize: 1 },
                        title: { display: true, text: 'Tage' },
                        stacked: false
                    },
                    x: {
                        title: { display: true, text: 'Monat' },
                        stacked: false
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const label = context.dataset.label || '';
                                const value = context.parsed.y;
                                const daysText = value === 1 ? 'Tag' : 'Tage';
                                return `${label}: ${value} ${daysText}`;
                            }
                        }
                    },
                    legend: {
                        display: true,
                        position: 'top',
                        onClick: function(e, legendItem, legend) {
                            Chart.defaults.plugins.legend.onClick.call(this, e, legendItem, legend);
                        }
                    }
                }
            }
        });
    }

    private updateAbsenceChart(): void {
        if (!this.absenceChart) {
            this.createAbsenceChart();
            return;
        }
        const sortedData = [...this.timeAccountSums].sort((a, b) => (a.year * 100 + a.month) - (b.year * 100 + b.month));
        this.absenceChart.data.labels = sortedData.map(item => {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
            return `${monthNames[item.month - 1]} ${item.year}`;
        });
        const absenceTypes = this.absenceTypes;
        while (this.absenceChart.data.datasets.length < absenceTypes.length) {
            const absenceType = absenceTypes[this.absenceChart.data.datasets.length];
            const isSollTage = absenceType.name.includes('Soll-Tage');
            const isIstTage = absenceType.name.includes('Ist-Tage');
            // Füge neuen Datensatz hinzu und cast ihn als AbsenceChartDataset
            (this.absenceChart.data.datasets as AbsenceChartDataset[]).push({
                label: absenceType.name,
                data: [],
                backgroundColor: absenceType.color + '80',
                borderColor: absenceType.color,
                borderWidth: 1,
                hidden: isSollTage || isIstTage,
                absenceId: absenceType.id
            } as AbsenceChartDataset);
        }
        for (let i = 0; i < absenceTypes.length; i++) {
            const absenceType = absenceTypes[i];
            this.absenceChart.data.datasets[i].data = sortedData.map(monthData => {
                // Add null check for AbsenceList before calling find
                const absenceEntry = monthData.AbsenceList && monthData.AbsenceList.find(abs => abs.id === absenceType.id);
                return absenceEntry ? absenceEntry.day_count : 0;
            });
            this.absenceChart.data.datasets[i].label = absenceType.name;
            this.absenceChart.data.datasets[i].backgroundColor = absenceType.color + '80';
            this.absenceChart.data.datasets[i].borderColor = absenceType.color;
            // Verwende Type Assertion, um auf absenceId zuzugreifen
            (this.absenceChart.data.datasets[i] as AbsenceChartDataset).absenceId = absenceType.id;
            const isSollTage = absenceType.name.includes('Soll-Tage');
            const isIstTage = absenceType.name.includes('Ist-Tage');
            this.absenceChart.data.datasets[i].hidden = isSollTage || isIstTage;
        }
        this.absenceChart.update();
    }

    private calculateAbsenceTypes(): AbsenceType[] {
        const absenceTypesMap = new Map<number, AbsenceType>();
        this.timeAccountSums.forEach(monthData => {
            if (monthData.AbsenceList) { // Check if AbsenceList exists
                monthData.AbsenceList.forEach(absence => {
                    if (!absenceTypesMap.has(absence.id)) {
                        absenceTypesMap.set(absence.id, {
                            id: absence.id,
                            name: absence.name,
                            totalDays: 0,
                            color: absence.color
                        });
                    }
                    const existingType = absenceTypesMap.get(absence.id);
                    if (existingType) {
                        existingType.totalDays += absence.day_count;
                    }
                });
            }
        });
        return Array.from(absenceTypesMap.values());
    }

    getAbsenceTypes(): AbsenceType[] {
        return this.absenceTypes;
    }

    private minutesToHours(minutes: number): number {
        return parseFloat((minutes / 60).toFixed(2));
    }

    minutesToTimeString(minutes: number): string {
        const hours = Math.floor(Math.abs(minutes) / 60);
        const mins = Math.abs(minutes) % 60;
        const sign = minutes < 0 ? '-' : '';
        return `${sign}${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
    }

    getTotalMinutes(type: 'target' | 'worked' | 'difference', numberOfMonths: number = 3): number {
        if (!this.timeAccountSums || this.timeAccountSums.length === 0) {
            return 0;
        }
        const sortedData = [...this.timeAccountSums].sort((a, b) => (b.year * 100 + b.month) - (a.year * 100 + a.month));
        const recentData = sortedData.slice(0, numberOfMonths);
        if (type === 'target') {
            return recentData.reduce((sum, item) => sum + item.target_minutes, 0);
        } else if (type === 'worked') {
            return recentData.reduce((sum, item) => sum + item.worked_minutes, 0);
        } else if (type === 'difference') {
            return recentData.reduce((sum, item) => sum + item.difference_minutes, 0);
        }
        return 0;
    }

    getDifferenceClass(minutes: number): string {
        return minutes < 0 ? 'bg-red-50' : 'bg-green-50';
    }

    // Filtert das Zeit-Chart anhand des angeklickten Typs (target, worked, difference)
    filterChart(type: 'target' | 'worked' | 'difference'): void {
        if (!this.chart) {
            return;
        }
        if (this.selectedDataset === type) {
            this.chart.data.datasets.forEach(dataset => dataset.hidden = false);
            this.selectedDataset = null;
        } else {
            this.chart.data.datasets.forEach((dataset, index) => {
                if (type === 'target' && index === 0) {
                    dataset.hidden = false;
                } else if (type === 'worked' && index === 1) {
                    dataset.hidden = false;
                } else if (type === 'difference' && index === 2) {
                    dataset.hidden = false;
                } else {
                    dataset.hidden = true;
                }
            });
            this.selectedDataset = type;
        }
        this.chart.update();
    }

    // Filtert das Abwesenheits-Chart anhand der angeklickten Abwesenheits-ID
    filterAbsenceChart(absenceId: number): void {
        if (!this.absenceChart) {
            return;
        }
        if (this.selectedAbsenceDataset === absenceId) {
            this.absenceChart.data.datasets.forEach(dataset => dataset.hidden = false);
            this.selectedAbsenceDataset = null;
        } else {
            this.absenceChart.data.datasets.forEach((dataset: ChartDataset<'bar', number[]>) => {
                // Cast zum Zugriff auf unsere zusätzliche Eigenschaft
                if ((dataset as AbsenceChartDataset).absenceId === absenceId) {
                    dataset.hidden = false;
                } else {
                    dataset.hidden = true;
                }
            });
            this.selectedAbsenceDataset = absenceId;
        }
        this.absenceChart.update();
    }
}
