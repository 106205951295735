<div class="time-account-chart-container">
    <div class="grid">
        <div class="col-12">
            <div class="chart-controls">
                <div class="flex justify-content-between align-items-center">
                    <div class="time-range-selector col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Zeitraum</mat-label>
                            <mat-select [formControl]="selectedTimeRange">
                                <mat-option *ngFor="let option of timeRangeOptions" [value]="option.value">
                                    {{ option.label }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <mat-tab-group class="col-12">
            <mat-tab label="{{ 'txZeitKonto.bookings' | translate }}">
                <div class="chart-wrapper" style="position: relative; height: 350px;">
                    <div *ngIf="loading" class="loading-overlay">
                        <div class="spinner">
                            <mat-spinner diameter="40"></mat-spinner>
                        </div>
                    </div>
                    <canvas #chartCanvas></canvas>
                </div>

                <h4 class="mt-3">Summen des angegebenen Zeitraums</h4>
                <div *ngIf="timeAccountSums.length > 0" class="statistics-summary mt-3">
                    <div class="grid">
                        <div class="col-12 md:col-4 statistic-item"
                             [ngClass]="{'selected': selectedDataset === 'target'}"
                             (click)="filterChart('target')">
                            <div class="statistic-card bg-blue-50">
                                <div class="statistic-title">Soll (letzte {{ selectedTimeRange.value }} Monate)</div>
                                <div class="statistic-value">
                                    {{ minutesToTimeString(getTotalMinutes('target', selectedTimeRange.value)) }}
                                </div>
                            </div>
                        </div>

                        <div class="col-12 md:col-4 statistic-item"
                             [ngClass]="{'selected': selectedDataset === 'worked'}"
                             (click)="filterChart('worked')">
                            <div class="statistic-card bg-teal-50">
                                <div class="statistic-title">Ist (letzte {{ selectedTimeRange.value }} Monate)</div>
                                <div class="statistic-value">
                                    {{ minutesToTimeString(getTotalMinutes('worked', selectedTimeRange.value)) }}
                                </div>
                            </div>
                        </div>

                        <div class="col-12 md:col-4 statistic-item"
                             [ngClass]="{'selected': selectedDataset === 'difference'}"
                             (click)="filterChart('difference')">
                            <div class="statistic-card" [ngClass]="getDifferenceClass(getTotalMinutes('difference', selectedTimeRange.value))">
                                <div class="statistic-title">Differenz (letzte {{ selectedTimeRange.value }} Monate)</div>
                                <div class="statistic-value">
                                    {{ minutesToTimeString(getTotalMinutes('difference', selectedTimeRange.value)) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Abwesenheiten">
                <div class="chart-wrapper" style="position: relative; height: 350px;">
                    <div *ngIf="loading" class="loading-overlay">
                        <div class="spinner">
                            <mat-spinner diameter="40"></mat-spinner>
                        </div>
                    </div>
                    <canvas #absenceChartCanvas></canvas>
                </div>

                <h4 class="mt-3">Abwesenheiten im angegebenen Zeitraum</h4>
                <div *ngIf="timeAccountSums.length > 0" class="statistics-summary mt-3">
                    <div class="grid">
                        <!-- Beim Klick auf die Karte wird filterAbsenceChart mit der jeweiligen Abwesenheits-ID aufgerufen -->
                        <div *ngFor="let absenceType of absenceTypes"
                             class="col-12 xl:col-2 lg:col-3 md:col-6 statistic-item"
                             [ngClass]="{'selected': selectedAbsenceDataset === absenceType.id}"
                             (click)="filterAbsenceChart(absenceType.id)">
                            <div class="statistic-card" [ngStyle]="{
								'background-color': absenceType.color + '10',
								'border-left': '4px solid ' + absenceType.color
							}">
                                <div class="statistic-title">{{ absenceType.name }} (letzte {{ selectedTimeRange.value }} Monate)</div>
                                <div class="statistic-value">
                                    {{ absenceType.totalDays }} {{ absenceType.totalDays === 1 ? 'Tag' : 'Tage' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
